import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { BasePostCommandComponent } from 'pbc.angular';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IBesichtigung, IGutachten, IPostGutachtenRequest, IPostGutachtenResponse } from 'fa-kt.types';
import { round } from 'lodash';
import { ISelection } from 'pbc.types';
import { ObjekteService } from '../../../../objekte';
import { PostGutachtenCommandService } from '../service';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'fa-kt-post-gutachten',
  templateUrl: './post-gutachten.component.html',
  styleUrls: ['./post-gutachten.component.css'],
})
export class PostGutachtenCommandComponent extends BasePostCommandComponent<IPostGutachtenRequest, IPostGutachtenResponse> implements OnInit, OnDestroy {
  description = { context: 'GUTACHTEN', command: 'POST_GUTACHTEN' };
  contentKey: keyof IPostGutachtenRequest = 'gutachten';

  $objekte = new BehaviorSubject<ISelection[]>([]);
  @Input() besichtigung: IBesichtigung | undefined;
  @Input() set gutachten(gutachten: Partial<IGutachten> | undefined) {
    if (gutachten) this.patch({ gutachten: gutachten as IGutachten });
  }

  @Output() sicherheitscheck = new EventEmitter<string>();
  @Output() selected = new EventEmitter<string | undefined>();

  constructor(
    public postGutachten: PostGutachtenCommandService,
    public objekte: ObjekteService,
  ) {
    super();
    this.form = this.fb.group({
      gutachten: this.fb.group({
        id: [null, []],
        projekt: [null, [Validators.required]],
        objekt: [null, [Validators.required]],
        bewertungsStatus: [null, [Validators.required]],
        objektNutzung: [null, []],
        objektArt: [null, []],
        sicherheitscheck: [null, []],
        abgabeDraft: [null, []],
        abgabeFinal: [null, []],
        datum_besichtigung: [null, []],
        erbbaurecht: [null, []],
        wohnenJRoE: [null, []],
        wohnenJRoEFlaeche: [null, []],
        gewerbeJRoE: [null, []],
        gewerbeJRoEFlaeche: [null, []],
        bueroJRoE: [null, []],
        bueroJRoEFlaeche: [null, []],
        handelJRoE: [null, []],
        handelJRoEFlaeche: [null, []],
        lagerJRoE: [null, []],
        lagerJRoEFlaeche: [null, []],
        sonstigesJRoE: [null, []],
        sonstigesJRoEFlaeche: [null, []],
        baujahr: [null, []],
        modernisierung: [null, []],
        marktwert: [null, []],
        stichtagMarktwert: [null, []],
        kaufpreis: [null, []],
        stichtagKaufpreis: [null, []],
        fortschritt: [null, []],
        aktiv: [null, []],
        kommentar: [null, []],
        tourenplanung: [null, []],
        jahresrohertrag: [null, []],
        flaeche: [null, []],
        gebaeudefaktor: [null, []],
        vervielfaeltiger: [null, []],
        besichtigung_kontakt: [null, []],
        besichtigung_email: [null, []],
        besichtigung_festnetz: [null, []],
        besichtigung_mobil: [null, []],
      }),
    });
    this.gutachtenJahresrohertrag?.disable();
    this.gutachtenFlaeche?.disable();
    this.patch();
  }

  async prepare(): Promise<void> {
    await this.postGutachten.prepare();
  }

  async request(payload: IPostGutachtenRequest): Promise<IPostGutachtenResponse> {
    return this.postGutachten.request(payload);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.objekte.result$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((result) => {
      if (!result) return;
      this.$objekte.next(
        result.objekte
          .map((row) => row.objekt)
          .map((objekt) => ({
            label: `${objekt.addresse.strasse ? objekt.addresse.strasse + ', ' : ''}${objekt.addresse.plz ? objekt.addresse.plz + ' ' : ''}${objekt.addresse.land ? objekt.addresse.land : ''}`,
            value: objekt.id,
          })),
      );
    });
    this.gutachtenObjekt?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (objekt) => {
      if (this.gutachtenObjekt?.touched) this.gutachtenSicherheitscheck?.patchValue(undefined);
    });
    this.gutachtenWohnenJRoE?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (wohnenJRoE) => this.calculateJahresrohertrag());
    this.gutachtenGewerbeJRoE?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (gewerbeJRoE) => this.calculateJahresrohertrag());
    this.gutachtenBueroJRoE?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (bueroJRoE) => this.calculateJahresrohertrag());
    this.gutachtenHandelJRoE?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (handelJRoE) => this.calculateJahresrohertrag());
    this.gutachtenLagerJRoE?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (lagerJRoE) => this.calculateJahresrohertrag());
    this.gutachtenSonstigesJRoE?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (sonstigesJRoE) => this.calculateJahresrohertrag());
    this.gutachtenLagerJRoEFlaeche?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (lagerJRoEFlaeche) => this.calculateFlaeche());
    this.gutachtenWohnenJRoEFlaeche?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (wohnenJRoEFlaeche) => this.calculateFlaeche());
    this.gutachtenGewerbeJRoEFlaeche?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (gewerbeJRoEFlaeche) => this.calculateFlaeche());
    this.gutachtenBueroJRoEFlaeche?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (bueroJRoEFlaeche) => this.calculateFlaeche());
    this.gutachtenHandelJRoEFlaeche?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (handelJRoEFlaeche) => this.calculateFlaeche());
    this.gutachtenSonstigesJRoEFlaeche?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (sonstigesJRoEFlaeche) => this.calculateFlaeche());
    this.gutachtenJahresrohertrag?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (jahresrohertrag) => this.calculateVervielfaeltiger());
    this.gutachtenMarktwert?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (marktwert) => this.calculateVervielfaeltiger());
    this.gutachtenFlaeche?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(() => this.calculateGebaeudefaktor());
    this.gutachtenMarktwert?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(() => this.calculateGebaeudefaktor());
    this.gutachtenTourenplanung?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (tourenplanung) => {
      if (tourenplanung) this.form.controls.gutachten.get('datum_besichtigung')?.patchValue(undefined);
    });
  }

  check() {
    if (this.gutachtenObjekt?.value) this.sicherheitscheck.emit(this.gutachtenObjekt?.value);
    else this.message.warning('Es muss ein Objekt für den Sicherheitscheck hinterlegt sein.');
  }

  calculateJahresrohertrag() {
    const gutachten: IGutachten = this.form.getRawValue().gutachten;
    const flaeche =
      Number(gutachten.wohnenJRoE) + Number(gutachten.bueroJRoE) + Number(gutachten.gewerbeJRoE) + Number(gutachten.lagerJRoE) + Number(gutachten.handelJRoE) + Number(gutachten.sonstigesJRoE);
    this.gutachtenJahresrohertrag?.patchValue(round(flaeche, 2));
  }

  calculateFlaeche() {
    const gutachten: IGutachten = this.form.getRawValue().gutachten;
    const flaeche =
      Number(gutachten.wohnenJRoEFlaeche) +
      Number(gutachten.bueroJRoEFlaeche) +
      Number(gutachten.gewerbeJRoEFlaeche) +
      Number(gutachten.lagerJRoEFlaeche) +
      Number(gutachten.handelJRoEFlaeche) +
      Number(gutachten.sonstigesJRoEFlaeche);
    this.gutachtenFlaeche?.patchValue(round(flaeche, 2));
  }

  calculateVervielfaeltiger() {
    const gutachten: IGutachten = this.form.getRawValue().gutachten;
    if (gutachten.marktwert && gutachten.jahresrohertrag) {
      const vervielfaeltiger = Number(gutachten.marktwert) / Number(gutachten.jahresrohertrag);
      this.gutachtenVerfielfaeltiger?.patchValue(round(vervielfaeltiger, 2));
    }
  }

  calculateGebaeudefaktor() {
    const gutachten: IGutachten = this.form.getRawValue().gutachten;
    if (gutachten.marktwert && gutachten.flaeche) {
      const gebaeudefaktor = Number(gutachten.marktwert) / Number(gutachten.flaeche);
      this.gutachtenGebaeudefaktor?.patchValue(round(gebaeudefaktor, 2));
    }
  }

  selectGutachten(id: string | undefined) {
    this.selected.emit(id);
  }
  /*    methods >> */

  get gutachtenId() {
    return this.form.controls.gutachten.get('id');
  }
  get gutachtenProjekt() {
    return this.form.controls.gutachten.get('projekt');
  }
  get gutachtenObjekt() {
    return this.form.controls.gutachten.get('objekt');
  }
  get gutachtenBewertungsStatus() {
    return this.form.controls.gutachten.get('bewertungsStatus');
  }
  get gutachtenObjektNutzung() {
    return this.form.controls.gutachten.get('objektNutzung');
  }
  get gutachtenObjektArt() {
    return this.form.controls.gutachten.get('objektArt');
  }
  get gutachtenSicherheitscheck() {
    return this.form.controls.gutachten.get('sicherheitscheck');
  }
  get gutachtenAbgabeDraft() {
    return this.form.controls.gutachten.get('abgabeDraft');
  }
  get gutachtenAbgabeFinal() {
    return this.form.controls.gutachten.get('abgabeFinal');
  }
  get gutachtenErbbaurecht() {
    return this.form.controls.gutachten.get('erbbaurecht');
  }
  get gutachtenWohnenJRoE() {
    return this.form.controls.gutachten.get('wohnenJRoE');
  }
  get gutachtenWohnenJRoEFlaeche() {
    return this.form.controls.gutachten.get('wohnenJRoEFlaeche');
  }
  get gutachtenGewerbeJRoE() {
    return this.form.controls.gutachten.get('gewerbeJRoE');
  }
  get gutachtenGewerbeJRoEFlaeche() {
    return this.form.controls.gutachten.get('gewerbeJRoEFlaeche');
  }
  get gutachtenBueroJRoE() {
    return this.form.controls.gutachten.get('bueroJRoE');
  }
  get gutachtenBueroJRoEFlaeche() {
    return this.form.controls.gutachten.get('bueroJRoEFlaeche');
  }
  get gutachtenHandelJRoE() {
    return this.form.controls.gutachten.get('handelJRoE');
  }
  get gutachtenHandelJRoEFlaeche() {
    return this.form.controls.gutachten.get('handelJRoEFlaeche');
  }
  get gutachtenLagerJRoE() {
    return this.form.controls.gutachten.get('lagerJRoE');
  }
  get gutachtenLagerJRoEFlaeche() {
    return this.form.controls.gutachten.get('lagerJRoEFlaeche');
  }
  get gutachtenSonstigesJRoE() {
    return this.form.controls.gutachten.get('sonstigesJRoE');
  }
  get gutachtenSonstigesJRoEFlaeche() {
    return this.form.controls.gutachten.get('sonstigesJRoEFlaeche');
  }
  get gutachtenBaujahr() {
    return this.form.controls.gutachten.get('baujahr');
  }
  get gutachtenModernisierung() {
    return this.form.controls.gutachten.get('modernisierung');
  }
  get gutachtenMarktwert() {
    return this.form.controls.gutachten.get('marktwert');
  }
  get gutachtenStichtagMarktwert() {
    return this.form.controls.gutachten.get('stichtagMarktwert');
  }
  get gutachtenKaufpreis() {
    return this.form.controls.gutachten.get('kaufpreis');
  }
  get gutachtenStichtagKaufpreis() {
    return this.form.controls.gutachten.get('stichtagKaufpreis');
  }
  get gutachtenFortschritt() {
    return this.form.controls.gutachten.get('fortschritt');
  }
  get gutachtenAktiv() {
    return this.form.controls.gutachten.get('aktiv');
  }
  get gutachtenKommentar() {
    return this.form.controls.gutachten.get('kommentar');
  }
  get gutachtenTourenplanung() {
    return this.form.controls.gutachten.get('tourenplanung');
  }
  get gutachtenJahresrohertrag() {
    return this.form.controls.gutachten.get('jahresrohertrag');
  }
  get gutachtenFlaeche() {
    return this.form.controls.gutachten.get('flaeche');
  }
  get gutachtenGebaeudefaktor() {
    return this.form.controls.gutachten.get('gebaeudefaktor');
  }
  get gutachtenVerfielfaeltiger() {
    return this.form.controls.gutachten.get('vervielfaeltiger');
  }
  get gutachtenEmail() {
    return this.form.controls.gutachten.get('besichtigung_email');
  }
  get gutachtenFestnetz() {
    return this.form.controls.gutachten.get('besichtigung_festnetz');
  }
  get gutachtenMobil() {
    return this.form.controls.gutachten.get('besichtigung_mobil');
  }

  formatter = (value: number): string => {
    console.log('format', value, '->', formatNumber(value, 'de-DE', '1.2-2'));
    return formatNumber(value, 'de-DE', '1.2-2');
  };
  parser = (value: string): number => {
    if (value?.endsWith(',')) value += '1';
    console.log('parse', value, value?.replace('.', '').replace(',', '.'), '->', Number.parseFloat(value?.replace('.', '').replace(',', '.')));
    return Number.parseFloat(value?.replace('.', '').replace(',', '.')) || 0;
  };

  public async navigateToTour(id: string | undefined) {
    if (!id) return;
    await this.router.navigate(['besichtigungen', 'tour'], { queryParams: { id } });
  }
}
