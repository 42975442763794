import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { formatNumber } from '@angular/common';

@Pipe({ name: 'formatHours' })
export class HourFormatPipe implements PipeTransform {
  transform(value?: number, turn = false, allowEmpty = false, brackets = false) {
    if (!value) return allowEmpty ? '' : '0m';
    if (turn && value < 0) value = value * -1;
    return (brackets ? '(' : '') + humanizeDuration(value, 'minutes') + (brackets ? ')' : '');
  }
}

function humanizeDuration(input: number, unit: moment.unitOfTime.Base): string {
  const duration = moment.duration(input, unit);
  let minutes = duration.asMinutes();
  const times = [];
  if (minutes < 0) times.push('-');
  minutes = Math.abs(minutes);
  if (Math.floor(minutes / 60)) times.push(formatNumber(Math.floor(minutes / 60), 'de-DE') + 'h');
  if (minutes % 60) times.push(formatNumber(minutes % 60, 'de-DE') + 'm');
  return times.join(' ');
}
