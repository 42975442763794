import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'pbc-multi-value',
  templateUrl: './multi-value.component.html',
  styleUrls: ['./multi-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiValueComponent,
    },
  ],
})
export class MultiValueComponent implements OnInit, OnDestroy, ControlValueAccessor {
  subscriptions: Subscription[] = [];

  onChange = (value: string | string[] | null) => {};
  onTouched = () => {};
  touched = false;

  @Input() disabled = false;
  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() mode: 'datum' | 'haken' | 'zahl' | 'text' | 'option' | 'optionPlus' | 'mehrfachauswahlPlus' = 'text';

  @Input() width = '100%';

  $value = new BehaviorSubject<any>(null);
  @Input() set value(value: any) {
    switch (this.mode) {
      case 'haken':
        value = Boolean(value);
    }
    this.$value.next(value ? value : undefined);
    this.onChange(value);
  }
  get value() {
    return this.$value.getValue();
  }
  @Output()
  valueChange: EventEmitter<string | string[]> = new EventEmitter();

  @Output()
  blurred: EventEmitter<string | string[]> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.valueChange.emit(obj);
  }
}
