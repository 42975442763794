import { AppEnvironment } from 'pbc.angular';

export const environment: AppEnvironment = {
  production: true,
  stage: 'production',
  serverURL: 'https://fa-kt-valuation.azurewebsites.net/api/v1/',
  appInsights: {
    instrumentationKey: '841b053f-1aa5-4bb0-a0bd-315c4269492c',
  },
  blobs: {
    driveID: 'b!pRwsZHg1K0CDGhIUpVVmn3oNHsf9oxlNgZ23sBDDxAGf5_tE20rRT73ktMdfzwop',
    baseUrl: 'https://graph.microsoft.com/v1.0',
    account: 'storageaccountfaktabe8d',
  },
  auth: {
    principal: '2941f114-af5b-4d34-bdc3-c91eb69ccc96',
    clientId: '7e7b2845-7d68-45b9-a999-ee04fb16507f',
    authority: 'https://login.microsoftonline.com/f8fa683f-82b6-4aaa-8cdc-852cacd82240',
    baseURL: 'https://graph.microsoft.com/v1.0/',
    scopes: [
      'Sites.ReadWrite.All',
      'Sites.Read.All',
      'Files.Read',
      'Files.Read.All',
      'Files.ReadWrite',
      'Files.ReadWrite.All',
      'Sites.Read.All',
      'Sites.ReadWrite.All',
      'ServiceHealth.Read.All',
      'Calendars.Read',
      'ExternalItem.Read.All',
      'Mail.Read',
      'User.Read',
      'Directory.AccessAsUser.All',
      'Directory.Read.All',
      'Directory.ReadWrite.All',
      'User.Read.All',
      'User.ReadBasic.All',
      'User.ReadWrite',
      'User.ReadWrite.All',
      'Contacts.Read',
      'Contacts.Read.Shared',
      'Contacts.ReadWrite',
      'Contacts.ReadWrite.Shared',
      'Directory.AccessAsUser.All',
      'Directory.ReadWrite.All',
      'Group.ReadWrite.All',
      'Group.Read.All',
      'Group.ReadWrite.All',
      'GroupMember.Read.All',
      'DeviceManagementApps.Read.All',
      'DeviceManagementApps.ReadWrite.All',
      'DeviceManagementConfiguration.Read.All',
      'DeviceManagementConfiguration.ReadWrite.All',
      'DeviceManagementManagedDevices.Read.All',
      'DeviceManagementManagedDevices.ReadWrite.All',
      'DeviceManagementServiceConfig.Read.All',
      'DeviceManagementServiceConfig.ReadWrite.All',
      'Directory.AccessAsUser.All',
      'Directory.Read.All',
      'Directory.ReadWrite.All',
      'User.Read.All',
      'User.ReadBasic.All',
      'User.ReadWrite.All',
      'Application.Read.All',
      'Application.ReadWrite.All',
      'Directory.AccessAsUser.All',
      'Directory.Read.All',
      'Directory.ReadWrite.All',
    ],
  },
  ids: {
    om: '0eef3936-137b-4413-8579-69f6a6e04a75',
    admin: '2451183e-84fa-4492-94aa-fa41c660f2e1',
    partner: '1e19f5e5-20f4-4ed0-9a72-42701c006f49',
  },
  shared: 'QeThWmZq4t7w!z%C*F-JaNdRfUjXn2r5u8x/A?D(G+KbPeShVkYp3s6v9y$B&E)H',
};
