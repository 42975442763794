import { Component, Directive, ElementRef, HostListener, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { formatNumber } from '@angular/common';

@Directive({
  selector: '[numberFormat]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputFormatterDirective),
      multi: true,
    },
  ],
})
export class NumberInputFormatterDirective implements ControlValueAccessor {
  @Input()
  set numberFormat(format: string) {}

  private el: HTMLInputElement;
  private _number: number;
  private _value: string;

  constructor(public hostElement: ElementRef) {
    this.el = this.hostElement.nativeElement;
  }

  get value(): number | null {
    return this._number;
  }

  @HostListener('input', ['$event'])
  change($event) {
    let item = $event.target;
    let pos = item.selectionStart;
    this.onBlur(item.value);
    if (this._value?.[pos] === '.') pos++;
    else if (this._value?.[pos] === ',') {
      if (this._value?.[pos + 1] === '0') pos--;
      else pos++;
    } else if (this._value?.[pos - 1] === ',') pos--;
    else if (this._value?.[pos - 2] === ',') pos--;
    this.el.setSelectionRange(pos + 1, pos + 1);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this._number = this.parseValue(this.formatValue(this.parseValue(value)));
    this._value = this.formatValue(this._number);
    this._onChange(this._number);
    if (value != null) this.hostElement.nativeElement.value = this._value;
  }

  writeValue(value: number | string) {
    if (typeof value === 'number') this._number = value;
    else this._number = this.parseValue(value);
    this._value = this.formatValue(this._number);
    if (value != null) this.hostElement.nativeElement.value = this._value;
  }

  private parseValue(value: string) {
    if (!value) return undefined;
    value = value?.toString().split('.').join('').split(',').join('.');
    return Number.parseFloat(value);
  }

  private formatValue(value: number) {
    if (!value) return null;
    return formatNumber(Number(value), 'de-DE', '1.2-2');
  }

  _onChange(value: any): void {}

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}
}
