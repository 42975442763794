<nz-spin [nzSpinning]="$loading | async">
  <nz-row>
    <nz-col nzSpan="24">
      <span>
        <span class="text-lg" style="width: 160px">Nr.</span>

        <span class="text-xl">Bezeichnung</span>

        <span class="text-md">Beauftragung</span>

        <span class="text-md">Deadline</span>

        <span style="width: 60px"> Besicht. </span>

        <span style="width: 60px"> Gutach. </span>

        <span style="width: 30px"> Plau. </span>

        <span class="text-lg" style="width: 180px !important"> Status </span>

        <span class="text-md">Art</span>

        <pbc-color-icon>Fortschritt</pbc-color-icon>

        <span>Kommentar</span>
      </span>
    </nz-col>
  </nz-row>
  <cdk-virtual-scroll-viewport *ngIf="archiv.result$ | async" [itemSize]="32" [style.height]="'calc(100vh - 332px)'">
    <nz-row nzSize="large">
      <nz-col
        nzSpan="24"
        style="display: block"
        *cdkVirtualFor="let entry of (archiv.result$ | async).archiv; let i = index"
        (click)="set(entry)"
        [style.backgroundColor]="i % 2 === 0 ? 'gainsboro' : ''"
      >
        <a>
          <span nz-typography nzType="secondary" class="text-lg" style="width: 160px">{{ entry.projekt.nummer }} </span>

          <span nz-typography nzType="secondary" class="text-xl">{{ entry.projekt.bezeichnung }} </span>

          <span nz-typography nzType="secondary" class="text-md" nz-tooltip nzTooltipTitle="Beauftragung"
            >{{ entry.projekt.beauftragung ? (entry.projekt.beauftragung | date: 'dd.MM.yyyy') : '?' }}
          </span>

          <span nz-typography nzType="secondary" class="text-md" nz-tooltip nzTooltipTitle="Deadline">{{ entry.projekt.deadline ? (entry.projekt.deadline | date: 'dd.MM.yyyy') : '?' }} </span>

          <nz-avatar-group nzSize="small" style="width: 60px">
            <ng-container [ngSwitch]="entry.projekt.keineBesichtigungErforderlich">
              <ng-container *ngSwitchCase="false">
                <nz-avatar
                  *ngFor="let mitarbeiter of entry.projekt?.besichtigungen"
                  [nzSize]="'small'"
                  [nzSrc]="mitarbeiter | type: (archiv.projektGutachten | async) : 'avatar' | safe: 'url'"
                  [nzText]="getMitarbeiterText(mitarbeiter, true)"
                  nz-tooltip
                  [nzTooltipTitle]="'Besichtigung: ' + getMitarbeiterText(mitarbeiter)"
                ></nz-avatar>
              </ng-container>
              <nz-avatar *ngSwitchDefault [nzSize]="'small'" nzIcon="close" nz-tooltip [nzTooltipTitle]="'Keine Besichtigung'"></nz-avatar>
            </ng-container>
          </nz-avatar-group>
          <nz-avatar-group nzSize="small" style="width: 60px">
            <nz-avatar
              *ngFor="let mitarbeiter of entry.projekt?.gutachten"
              [nzSize]="'small'"
              [nzSrc]="mitarbeiter | type: (archiv.projektGutachten | async) : 'avatar' | safe: 'url'"
              [nzText]="getMitarbeiterText(mitarbeiter, true)"
              nz-tooltip
              [nzTooltipTitle]="'Gutachten: ' + getMitarbeiterText(mitarbeiter)"
            ></nz-avatar>
          </nz-avatar-group>
          <nz-avatar-group nzSize="small" style="width: 30px">
            <nz-avatar
              [nzSize]="'small'"
              [nzSrc]="entry.projekt.pruefung | type: (archiv.projektPruefung | async) : 'avatar' | safe: 'url'"
              [nzText]="getMitarbeiterText(entry.projekt.pruefung, true)"
              nz-tooltip
              [nzTooltipTitle]="'Plausibilisierung: ' + getMitarbeiterText(entry.projekt.pruefung)"
            ></nz-avatar>
          </nz-avatar-group>

          <span class="text-lg" style="width: 180px !important" [style.color]="entry.projekt.projektStatus | type: (archiv.projektProjektStatus | async) : 'color'">
            <i nz-icon [nzType]="entry.projekt.projektStatus | type: (archiv.projektProjektStatus | async) : 'icon'" nzTheme="outline"></i>
            {{ entry.projekt.projektStatus | type: (archiv.projektProjektStatus | async) }}
          </span>

          <span nz-typography class="text-md">{{ entry.projekt.projektArt | type: (archiv.projektProjektArt | async) }}</span>

          <pbc-color-icon [color]="getColorByFortschritt(entry.projekt.fortschritt)" nz-tooltip [nzTooltipTitle]="(entry.projekt.fortschritt || 0) + ' % Fortschritt'"></pbc-color-icon>

          <a nz-tooltip [nzTooltipTitle]="entry.projekt.kommentar"><i nz-icon nzType="comment" nzTheme="outline"></i></a>
        </a>
      </nz-col>
    </nz-row>
  </cdk-virtual-scroll-viewport>
</nz-spin>
