<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="zeiten.search$.next($event)" [search]="zeiten.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-zeiten-filter [style.width]="'100%'"></fa-kt-zeiten-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-zeiten-sorter [style.width]="'100%'"></fa-kt-zeiten-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-zeiten-list [style.width]="'100%'" (select)="setZeiten($event)" [loading]="$loading | async"></fa-kt-zeiten-list>


  <fa-kt-post-zeit [style.width]="'100%'"  [value]="$postZeit | async" (finished)="finishedPostZeit($event)"></fa-kt-post-zeit>

  <fa-kt-delete-zeit [style.width]="'100%'"  [value]="$deleteZeit | async" (finished)="finishedDeleteZeit($event)"></fa-kt-delete-zeit>


  <fa-kt-zeit [style.width]="'100%'" ></fa-kt-zeit>


-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <button nz-button *nzSpaceItem (click)="loadZeiten(jahr, true)" nzType="dashed">
        <i nz-icon nzType="reload" nzTheme="outline"></i>
        Neu laden
      </button>
      <nz-select *nzSpaceItem [(ngModel)]="jahr" (ngModelChange)="loadZeiten($event)">
        <nz-option *ngFor="let jahr of jahre" [nzValue]="jahr" [nzLabel]="'Jahr ' + jahr"></nz-option>
      </nz-select>
      <button
        *nzSpaceItem
        nz-button
        (click)="createUrlaub()"
        nzType="primary"
        nz-tooltip
        [nzTooltipTitle]="Urlaub"
        [nzTooltipColor]="((urlaub$ | async)?.jahr?.['verfügbar'] || 0) > 0 ? 'green' : 'red'"
      >
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        Urlaub
        <nz-tag style="margin-left: 8px" *ngIf="(urlaub$ | async)?.jahr.erlaubt" [nzColor]="((urlaub$ | async)?.jahr['verfügbar']) > 0 ? 'green' : 'red'" [nzBordered]="false"
          >{{ ((urlaub$ | async)?.jahr['verfügbar'] || 0) | number: '0.0-2' }} Urlaubstage</nz-tag
        >
        <span nz-icon nzType="picture" nzTheme="outline"></span>

        <ng-template #Urlaub>
          <ng-container *ngIf="!(urlaub$ | async)?.jahr.erlaubt">
            <span><i>Urlaubskonto noch nicht berechnet. Bitte Urlaub einreichen, o.ä. zu Speichern des Urlaubskontos.</i></span
            ><br /><br />
          </ng-container>
          {{ ((urlaub$ | async)?.["übertrag"]["erlaubt"] || 0) | number:'0.0-0' }} Tage Urlaub aus Übertrag des Vorjahres<br />
          - {{ ((urlaub$ | async)?.["übertrag"]["genommen"] || 0) | number:'0.0-0' }} Tage Urlaub bis {{ (urlaub$ | async)?.["übertrag"].bis | date:'MMMM yyyy':'de' }} genommen<br />
          = {{ ((urlaub$ | async)?.["übertrag"]["verfügbar"] || 0) | number:'0.0-0' }} Tage Rest aus Übertrag (Ablauf Ende {{ (urlaub$ | async)?.["übertrag"].bis | date:'MMMM yyyy':'de' }})<br /><br />
          + {{ ((urlaub$ | async)?.jahr?.erlaubt || 0) | number:'0.0-0' }} Tage Urlaub im Jahr erlaubt<br />
          - {{ ((urlaub$ | async)?.jahr?.genommen || 0) | number:'0.0-0' }} Tage Urlaub ab {{ (urlaub$ | async)?.["übertrag"].bis | date:'MMMM yyyy':'de' }} genommen<br /><br />
          <u>= {{ ((urlaub$ | async)?.jahr?.['verfügbar'] || 0) | number:'0.0-0' }} Tage Resturlaub</u>
        </ng-template>
      </button>
      <button *nzSpaceItem nz-button (click)="createKrankheit()" nzType="primary">
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        Krankmeldung
        <span nz-icon nzType="medicine-box" nzTheme="outline"></span>
      </button>
      <button *nzSpaceItem nz-button (click)="createRahmenAnpassung()" nzType="primary">
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        Erlaubnis für Über/Unterschreitungen
        <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
      </button>
    </nz-space>
  </nz-page-header-extra>
  <nz-page-header-content>
    <ul nz-menu nzMode="horizontal" nzSize="large" nzCentered>
      <li nz-menu-item [nzSelected]="($tab | async) === 'konten'" *ngIf="(auth.$isFinanzen | async) || (auth.$isOM | async) || (auth.$isPartner | async) || (auth.$isUrlaubsAdmin | async)">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'konten' }" queryParamsHandling="merge" replaceUrl="true">
          <i nz-icon nzType="rise" nzTheme="outline" style="margin-right: 8px"></i>
          {{ (mitarbeiter$ | async)?.length || 0 }} Übersichten
        </a>
      </li>
      <li nz-menu-item [nzSelected]="($tab | async) === 'tage'">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'tage' }" queryParamsHandling="merge" replaceUrl="true">
          <span nz-icon nzType="clock-circle" nzTheme="outline" style="margin-right: 8px"></span>
          {{ ((tage$ | async)?.length || 0) | number:'0.0-0' }} Tag{{ ((tage$ | async)?.length || 0) === 1 ? '' : 'e' }} ({{$keineGesetzlichePause | async | number:'0.0-0' }}
          <span nz-icon nzType="alert" nzTheme="outline" style="margin-left: -3px"></span>)
        </a>
      </li>
      <li nz-menu-item [nzSelected]="($tab | async) === 'zeiten'">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'zeiten' }" queryParamsHandling="merge" replaceUrl="true">
          <span nz-icon nzType="ordered-list" nzTheme="outline" style="margin-right: 8px"></span>
          {{ ((zeiten.result$ | async)?.zeiten?.length || 0) | number:'0.0-0' }} Aktivität{{ ((zeiten.result$ | async)?.zeiten?.length || 0) === 1 ? '' : 'en' }} ({{$stunden| async | number:'0.0-0'}}h
          {{ $minuten | async }}m)
        </a>
      </li>
      <li nz-menu-item [nzSelected]="($tab | async) === 'urlaube'">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'urlaube' }" queryParamsHandling="merge" replaceUrl="true">
          <nz-badge [nzCount]="($offeneUrlaube | async)">
            <span nz-icon nzType="picture" nzTheme="outline" style="margin-right: 8px"></span>
            {{ ((urlaube.result$ | async)?.urlaube?.length || 0) | number:'0.0-0' }} Urlaub{{ ((urlaube.result$ | async)?.urlaube?.length || 0) === 1 ? '' : 'e' }} (~{{$urlaubstage | async |
            number:'0.0-0' }} Tag{{ ($urlaubstage | async) === 1 ? '' : 'e' }})
          </nz-badge>
        </a>
      </li>
      <li nz-menu-item [nzSelected]="($tab | async) === 'krankheiten'">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'krankheiten' }" queryParamsHandling="merge" replaceUrl="true">
          <nz-badge [nzCount]="($offeneKrankheiten | async)">
            <span nz-icon nzType="medicine-box" nzTheme="outline" style="margin-right: 8px"></span>
            {{ (krankheiten.result$ | async)?.krankheiten?.length || 0 | number:'0.0-0' }} Krankmeldung{{ ((krankheiten.result$ | async)?.krankheiten?.length || 0) === 1 ? '' : 'en' }}
            (~{{$krankheitstage | async | number:'0.0-0' }} Tag{{ ($krankheitstage | async) === 1 ? '' : 'e' }})
          </nz-badge>
        </a>
      </li>
      <li nz-menu-item [nzSelected]="($tab | async) === 'rahmen-anpassungen'">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'rahmen-anpassungen' }" queryParamsHandling="merge" replaceUrl="true">
          <nz-badge [nzCount]="($offeneRahmenAnpassungen | async)">
            <span nz-icon nzType="clock-circle" nzTheme="outline" style="margin-right: 8px"></span>
            {{ ((rahmenAnpassungen.result$ | async)?.rahmenAnpassungen?.length || 0) | number:'0.0-0' }} Erlaubnis{{ ((rahmenAnpassungen.result$ | async)?.rahmenAnpassungen?.length || 0) === 1 ? '' :
            'se' }} für Über/Unterschreitungen
          </nz-badge>
        </a>
      </li>
    </ul>
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <ng-container *ngIf="($tab | async) === 'zeiten'">
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col nzSpan="20">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="zeiten.search$.next($event)" [search]="zeiten.search$ | async"></pbc-search-bar>
      </nz-col>
      <nz-col nzSpan="1"> </nz-col>
      <nz-col nzSpan="3">
        <fa-kt-saved-zeiten-filters style="float: right; width: 100%"></fa-kt-saved-zeiten-filters>
      </nz-col>
    </nz-row>
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
        <fa-kt-zeiten-filter [style.width]="'100%'"></fa-kt-zeiten-filter>
      </nz-col>
      <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
        <fa-kt-zeiten-sorter [style.width]="'100%'"></fa-kt-zeiten-sorter>
      </nz-col>
    </nz-row>

    <nz-row>
      <nz-col nzSpan="24">
        <fa-kt-zeiten-list [style.width]="'100%'" (select)="setZeiten($event)" [loading]="$loading | async"></fa-kt-zeiten-list>
      </nz-col>
    </nz-row>
  </ng-container>

  <ng-container *ngIf="($tab | async) === 'tage'">
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col nzSpan="20">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="tage.search$.next($event)" [search]="tage.search$ | async"></pbc-search-bar>
      </nz-col>
      <nz-col nzSpan="1"> </nz-col>
      <nz-col nzSpan="3">
        <fa-kt-saved-tage-filters style="float: right; width: 100%"></fa-kt-saved-tage-filters>
      </nz-col>
    </nz-row>
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
        <fa-kt-tage-filter [style.width]="'100%'"></fa-kt-tage-filter>
      </nz-col>
      <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
        <fa-kt-tage-sorter [style.width]="'100%'"></fa-kt-tage-sorter>
      </nz-col>
    </nz-row>

    <nz-row>
      <nz-col nzSpan="24">
        <fa-kt-tage-list [style.width]="'100%'" [loading]="$loading | async"></fa-kt-tage-list>
      </nz-col> </nz-row
  ></ng-container>

  <ng-container *ngIf="($tab | async) === 'urlaube'">
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col nzSpan="20">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="urlaube.search$.next($event)" [search]="urlaube.search$ | async"></pbc-search-bar>
      </nz-col>
      <nz-col nzSpan="1"> </nz-col>
      <nz-col nzSpan="3">
        <fa-kt-urlaube-presets style="float: right; width: 100%"></fa-kt-urlaube-presets>
      </nz-col>
    </nz-row>
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
        <fa-kt-urlaube-filter [style.width]="'100%'"></fa-kt-urlaube-filter>
      </nz-col>
      <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
        <fa-kt-urlaube-sorter [style.width]="'100%'"></fa-kt-urlaube-sorter>
      </nz-col>
    </nz-row>

    <nz-row>
      <nz-col nzSpan="24">
        <cdk-virtual-scroll-viewport *ngIf="urlaube.result$ | async" [itemSize]="69" [style.height]="'calc(100vh - 324px)'">
          <nz-list nzSize="large" [nzLoading]="$loading | async">
            <nz-list-item *cdkVirtualFor="let entry of (urlaube.result$ | async).urlaube | reverse" (click)="openUrlaub(entry.urlaub.id)">
              <nz-list-item-meta>
                <nz-list-item-meta-avatar>
                  <nz-avatar
                    [nzSrc]="entry.urlaub.mitarbeiter | type: (meta.users$ | async) : 'avatar' | safe:'url'"
                    [nzText]="entry.urlaub.mitarbeiter | type: (meta.users$ | async) : 'initialen'"
                    nz-tooltip
                    [nzTooltipTitle]="entry.urlaub.mitarbeiter | type: (meta.users$ | async) : 'label'"
                  >
                  </nz-avatar>
                </nz-list-item-meta-avatar>
                <nz-list-item-meta-title>
                  <a>
                    <span nz-typography>{{ entry.urlaub.art || 'Urlaub' }}</span>
                    <nz-divider nzType="vertical"></nz-divider>
                    <span nz-typography nzType="secondary">{{ entry.urlaub.von | date:'dd.MM.yyyy' }} - {{ entry.urlaub.bis | date:'dd.MM.yyyy' }}</span>
                    <nz-divider nzType="vertical"></nz-divider>
                    <span nz-typography nzType="secondary" nz-tooltip nzTooltipTitle="Die hier angezeigte Tagesanzahl berücksichtigt nicht das jeweilige Bundesland.">
                      ~{{ getArbeitstage(entry.urlaub.von, entry.urlaub.bis) }} Arbeitstage
                    </span>
                    <nz-divider nzType="vertical"></nz-divider>
                    <nz-tag *ngIf="entry.urlaub.abgelehnt" [nzColor]="'red'">Abgelehnt</nz-tag>
                    <nz-tag *ngIf="entry.urlaub?.angenommen" [nzColor]="'green'">Angenommen</nz-tag>
                    <nz-tag *ngIf="!entry.urlaub.angenommen && !entry.urlaub.abgelehnt" [nzColor]="'blue'">Offen</nz-tag>
                    <ng-container *ngIf="entry.urlaub.kommentar">
                      <nz-divider nzType="vertical"></nz-divider>
                      <span nz-typography nzType="secondary">{{ entry.urlaub.kommentar }}</span>
                    </ng-container>
                  </a>
                </nz-list-item-meta-title>
              </nz-list-item-meta>
              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
                </nz-list-item-action>
              </ul>
            </nz-list-item>
          </nz-list>
        </cdk-virtual-scroll-viewport>
      </nz-col>
    </nz-row>
  </ng-container>

  <ng-container *ngIf="($tab | async) === 'krankheiten'">
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col nzSpan="20">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="krankheiten.search$.next($event)" [search]="krankheiten.search$ | async"></pbc-search-bar>
      </nz-col>
      <nz-col nzSpan="1"> </nz-col>
      <nz-col nzSpan="3">
        <fa-kt-krankheiten-presets style="float: right; width: 100%"></fa-kt-krankheiten-presets>
      </nz-col>
    </nz-row>
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
        <fa-kt-krankheiten-filter [style.width]="'100%'"></fa-kt-krankheiten-filter>
      </nz-col>
      <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
        <fa-kt-krankheiten-sorter [style.width]="'100%'"></fa-kt-krankheiten-sorter>
      </nz-col>
    </nz-row>
    <nz-row>
      <nz-col nzSpan="24">
        <cdk-virtual-scroll-viewport *ngIf="krankheiten.result$ | async" [itemSize]="69" [style.height]="'calc(100vh - 324px)'">
          <nz-list nzSize="large" [nzLoading]="$loading | async">
            <nz-list-item *cdkVirtualFor="let entry of (krankheiten.result$ | async).krankheiten | reverse" (click)="openKrankheit(entry.krankheit.id)">
              <nz-list-item-meta>
                <nz-list-item-meta-avatar>
                  <nz-avatar
                    [nzSrc]="entry.krankheit.mitarbeiter | type: (meta.users$ | async) : 'avatar' | safe:'url'"
                    [nzText]="entry.krankheit.mitarbeiter | type: (meta.users$ | async) : 'initialen'"
                    nz-tooltip
                    [nzTooltipTitle]="entry.krankheit.mitarbeiter | type: (meta.users$ | async) : 'label'"
                  >
                  </nz-avatar>
                </nz-list-item-meta-avatar>
                <nz-list-item-meta-title>
                  <a>
                    <span nz-typography nzType="secondary">{{ entry.krankheit.von | date:'dd.MM.yyyy' }} - {{ entry.krankheit.bis | date:'dd.MM.yyyy' }}</span>
                    <nz-divider nzType="vertical"></nz-divider>
                    <span nz-typography nzType="secondary" nz-tooltip nzTooltipTitle="Die hier angezeigte Tagesanzahl berücksichtigt nicht das jeweilige Bundesland.">
                      ~{{ getArbeitstage(entry.krankheit.von, entry.krankheit.bis) }} Arbeitstage</span
                    >
                    <nz-divider nzType="vertical"></nz-divider>
                    <nz-tag *ngIf="!entry.krankheit.bescheinigt" [nzColor]="'blue'">Offen</nz-tag>
                    <nz-tag *ngIf="entry.krankheit.bescheinigt" [nzColor]="'green'">Bescheinigt</nz-tag>
                    <ng-container *ngIf="entry.krankheit.kommentar">
                      <nz-divider nzType="vertical"></nz-divider>
                      <span nz-typography nzType="secondary">{{ entry.krankheit.kommentar }}</span>
                    </ng-container>
                  </a>
                </nz-list-item-meta-title>
              </nz-list-item-meta>
              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
                </nz-list-item-action>
              </ul>
            </nz-list-item>
          </nz-list>
        </cdk-virtual-scroll-viewport>
      </nz-col>
    </nz-row>
  </ng-container>

  <ng-container *ngIf="($tab | async) === 'rahmen-anpassungen'">
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col nzSpan="20">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="rahmenAnpassungen.search$.next($event)" [search]="rahmenAnpassungen.search$ | async"></pbc-search-bar>
      </nz-col>
      <nz-col nzSpan="1"> </nz-col>
      <nz-col nzSpan="3">
        <fa-kt-rahmen-anpassungen-presets style="float: right; width: 100%"></fa-kt-rahmen-anpassungen-presets>
      </nz-col>
    </nz-row>
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
        <fa-kt-rahmen-anpassungen-filter [style.width]="'100%'"></fa-kt-rahmen-anpassungen-filter>
      </nz-col>
      <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
        <fa-kt-rahmen-anpassungen-sorter [style.width]="'100%'"></fa-kt-rahmen-anpassungen-sorter>
      </nz-col>
    </nz-row>
    <nz-row>
      <nz-col nzSpan="24">
        <cdk-virtual-scroll-viewport *ngIf="rahmenAnpassungen.result$ | async" [itemSize]="69" [style.height]="'calc(100vh - 324px)'">
          <nz-list nzSize="large" [nzLoading]="$loading | async">
            <nz-list-item *cdkVirtualFor="let entry of (rahmenAnpassungen.result$ | async).rahmenAnpassungen | reverse" (click)="openRahmenAnpassung(entry.rahmenAnpassung.id)">
              <nz-list-item-meta>
                <nz-list-item-meta-avatar>
                  <nz-avatar
                    [nzSrc]="entry.rahmenAnpassung.mitarbeiter | type: (meta.users$ | async) : 'avatar' | safe:'url'"
                    [nzText]="entry.rahmenAnpassung.mitarbeiter | type: (meta.users$ | async) : 'initialen'"
                    nz-tooltip
                    [nzTooltipTitle]="entry.rahmenAnpassung.mitarbeiter | type: (meta.users$ | async) : 'label'"
                  >
                  </nz-avatar>
                </nz-list-item-meta-avatar>
                <nz-list-item-meta-title>
                  <a>
                    <span nz-typography nzType="secondary">{{ (entry.rahmenAnpassung.stunden || 0) | number:'0.0-0' }} Stunde{{ entry.rahmenAnpassung.stunden === 1 ? '' : 'n' }}</span>
                    <nz-divider nzType="vertical"></nz-divider>
                    <nz-tag *ngIf="entry.rahmenAnpassung.abgelehnt" [nzColor]="'red'">Abgelehnt</nz-tag>
                    <nz-tag *ngIf="entry.rahmenAnpassung.angenommen" [nzColor]="'green'">Angenommen</nz-tag>
                    <nz-tag *ngIf="!entry.rahmenAnpassung.angenommen && !entry.rahmenAnpassung.abgelehnt" [nzColor]="'blue'">Offen</nz-tag>
                    <ng-container *ngIf="entry.rahmenAnpassung.kommentar">
                      <nz-divider nzType="vertical"></nz-divider>
                      <span nz-typography nzType="secondary">{{ entry.rahmenAnpassung.kommentar }}</span>
                    </ng-container>
                    <ng-container *ngIf="entry.rahmenAnpassung['gültigBis']">
                      <nz-divider nzType="vertical"></nz-divider>
                      Gültig bis: {{ entry.rahmenAnpassung['gültigBis'] | date: 'dd.MM.yyyy HH:mm' }}
                    </ng-container>
                  </a>
                </nz-list-item-meta-title>
              </nz-list-item-meta>
              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
                </nz-list-item-action>
              </ul>
            </nz-list-item>
          </nz-list>
        </cdk-virtual-scroll-viewport>
      </nz-col>
    </nz-row>
  </ng-container>

  <ng-container *ngIf="($tab | async) === 'konten'">
    <nz-row>
      <nz-col nzSpan="24">
        <cdk-virtual-scroll-viewport [itemSize]="69" [style.height]="'calc(100vh - 324px)'">
          <nz-list nzSize="large" [nzLoading]="$loading | async">
            <nz-list-item *cdkVirtualFor="let mitarbeiter of mitarbeiter$ | async | order:'minuten.konto'" [routerLink]="['/', 'personen', 'profil']" [queryParams]="{ id: mitarbeiter.id}">
              <nz-list-item-meta>
                <nz-list-item-meta-avatar>
                  <nz-avatar
                    [nzSrc]="mitarbeiter.id | type: (meta.users$ | async) : 'avatar' | safe:'url'"
                    [nzText]="mitarbeiter.id | type: (meta.users$ | async) : 'initialen'"
                    nz-tooltip
                    [nzTooltipTitle]="mitarbeiter.id | type: (meta.users$ | async) : 'label'"
                  >
                  </nz-avatar>
                </nz-list-item-meta-avatar>
                <nz-list-item-meta-title>
                  <a>
                    {{ mitarbeiter.anzeigename }}
                    <nz-divider nzType="vertical"></nz-divider>
                    <span nz-typography nzType="secondary">
                      {{ mitarbeiter.stundenProTag || stunden_pro_tag }} Stunde{{ (mitarbeiter.stundenProTag || stunden_pro_tag) === 1 ? '' : 'n' }} pro Tag
                    </span>
                    <nz-divider nzType="vertical"></nz-divider>
                    <mark nz-typography nzType="secondary" *ngIf="!mitarbeiter.minuten"><i>noch nicht gespeichert</i></mark>
                    <nz-tag
                      *ngIf="mitarbeiter.minuten"
                      style="margin-left: 16px; margin-right: 16px"
                      [nzBordered]="false"
                      nz-tooltip
                      [nzTooltipTitle]="Konto"
                      [nzColor]="mitarbeiter.minuten?.['drüber'] ? mitarbeiter.minuten?.['drüber'] > 0 ? 'red' : 'green' : mitarbeiter.minuten?.drunter < 0 ? 'red' : 'green'"
                      [nzTooltipColor]="mitarbeiter.minuten?.['drüber'] ? mitarbeiter.minuten?.['drüber'] > 0 ? 'red' : 'green' : mitarbeiter.minuten?.drunter < 0 ? 'red' : 'green'"
                    >
                      {{ (mitarbeiter.minuten?.konto || 0) | formatHours }} auf dem Konto
                    </nz-tag>
                    <ng-template #Konto>
                      <span>
                        <ng-container *ngIf="mitarbeiter.minuten?.base">
                          {{mitarbeiter.minuten?.base?.days}} Tage zwischen {{ mitarbeiter.minuten?.base?.from | date:'dd.MM.yy':'de' }} und {{ mitarbeiter.minuten?.base?.to | date:'dd.MM.yy':'de'
                          }}<br />
                          <ng-container *ngIf="mitarbeiter.minuten?.base?.weekend > 0">- {{ (mitarbeiter.minuten.base.weekend) }} Tage Wochenende <br /></ng-container>
                          <ng-container *ngIf="mitarbeiter.minuten?.base?.feiertage > 0">- {{ (mitarbeiter.minuten.base.feiertage) }} Feiertage <br /></ng-container><br />
                        </ng-container>
                        = {{ (mitarbeiter.minuten?.erwartet || 0) | formatHours }} erwartet {{ mitarbeiter.minuten?.base ? ' aus ' + (mitarbeiter.minuten?.base?.working || 0) + ' Arbeitstagen' : ''
                        }}<br />
                        - {{ (mitarbeiter.minuten?.gearbeitet || 0) | formatHours }} eingetragene Aktivitäten <br />
                        <ng-container *ngIf="mitarbeiter.minuten?.holiday > 0">- {{ mitarbeiter.minuten.holiday | formatHours }} eingetragener Urlaub <br /></ng-container>
                        <ng-container *ngIf="mitarbeiter.minuten?.sickness > 0">- {{ mitarbeiter.minuten.sickness | formatHours }} eingetragene Krankheit <br /></ng-container>
                        = {{ (mitarbeiter.minuten?.konto || 0) | formatHours }} Ergebnis<br /><br />
                        {{mitarbeiter.minuten?.konto > 0 ? '-' : '+'}} {{ (mitarbeiter.minuten?.rahmen || 0) | formatHours: true }} {{ (mitarbeiter.minuten?.konto || 0) > 0 ? 'Über' : 'Unter'
                        }}stunden-Rahmen<br />
                        <u
                          ><span *ngIf="mitarbeiter.minuten?.['drüber']">
                            = {{ (mitarbeiter.minuten['drüber'] < 0 ? (mitarbeiter.minuten['drüber']) * -1 : mitarbeiter.minuten['drüber']) | formatHours }} {{ mitarbeiter.minuten['drüber'] > 0 ? 'zu
                            viel gearbeitet' : 'bis Überschreitung' }}
                          </span>
                          <span *ngIf="mitarbeiter.minuten?.drunter">
                            = {{ (mitarbeiter.minuten?.drunter < 0 ? (mitarbeiter.minuten?.drunter) * -1 : mitarbeiter.minuten?.drunter) | formatHours }} {{ mitarbeiter.minuten?.drunter < 0 ? 'zu
                            wenig gearbeitet' : 'bis Unterschreitung' }}
                          </span></u
                        >
                      </span>
                    </ng-template>
                    <nz-divider nzType="vertical"></nz-divider>
                    <mark nz-typography nzType="secondary" *ngIf="!mitarbeiter.urlaub"><i>noch nicht gespeichert</i></mark>
                    <nz-tag
                      *ngIf="mitarbeiter.urlaub"
                      [nzColor]="(mitarbeiter.urlaub?.jahr?.['verfügbar'] || 0) > 0 ? 'green' : 'red'"
                      [nzBordered]="false"
                      nz-tooltip
                      [nzTooltipTitle]="Urlaub"
                      [nzTooltipColor]="(mitarbeiter.urlaub?.jahr?.['verfügbar'] || 0) > 0 ? 'green' : 'red'"
                    >
                      Noch {{ (mitarbeiter.urlaub?.jahr?.['verfügbar'] || 0) | number:'0.0-0' }} Urlaubstage</nz-tag
                    >
                    <ng-template #Urlaub>
                      {{ (mitarbeiter.urlaub?.["übertrag"]["erlaubt"] || 0) | number:'0.0-0' }} Tage Urlaub aus Übertrag des Vorjahres<br />
                      - {{ (mitarbeiter.urlaub?.["übertrag"]["genommen"] || 0) | number:'0.0-0' }} Tage Urlaub bis {{ mitarbeiter.urlaub?.["übertrag"].bis | date:'MMMM yyyy':'de' }} genommen<br />
                      = {{ (mitarbeiter.urlaub?.["übertrag"]["verfügbar"] || 0) | number:'0.0-0' }} Tage Rest aus Übertrag (Ablauf Ende {{ mitarbeiter.urlaub?.["übertrag"].bis | date:'MMMM yyyy':'de'
                      }})<br /><br />
                      + {{ (mitarbeiter.urlaub?.jahr?.erlaubt || 0) | number:'0.0-0' }} Tage Urlaub im Jahr erlaubt<br />
                      - {{ (mitarbeiter.urlaub?.jahr?.genommen || 0) | number:'0.0-0' }} Tage Urlaub ab {{ mitarbeiter.urlaub?.["übertrag"].bis | date:'MMMM yyyy':'de' }} genommen<br /><br />
                      <u>= {{ (mitarbeiter.urlaub?.jahr?.['verfügbar'] || 0) | number:'0.0-0' }} Tage Resturlaub</u>
                    </ng-template>
                  </a>
                </nz-list-item-meta-title>
              </nz-list-item-meta>
              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
                </nz-list-item-action>
              </ul>
            </nz-list-item>
          </nz-list>
        </cdk-virtual-scroll-viewport>
      </nz-col>
    </nz-row>
  </ng-container>

  <fa-kt-zeit [id]="$id | async" (changed)="$id.next($event)" [style.width]="'100%'"></fa-kt-zeit>

  <nz-modal [nzTitle]="UrlaubModalTitle" [nzContent]="UrlaubModalContent" [nzFooter]="UrlaubModalFooter" (nzOnCancel)="openUrlaub()" [nzVisible]="!!($urlaub | async)">
    <ng-template #UrlaubModalTitle>
      <nz-page-header [nzTitle]="UrlaubModalHeaderTitle">
        <ng-template #UrlaubModalHeaderTitle> <span nz-icon nzType="picture" nzTheme="outline" style="margin-right: 8px"></span> Urlaub </ng-template>
        <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
        <nz-page-header-tags *ngIf="($urlaub | async)?.id">
          <nz-tag *ngIf="($urlaub | async)?.abgelehnt" [nzColor]="'red'">Abgelehnt</nz-tag>
          <nz-tag *ngIf="($urlaub | async)?.angenommen" [nzColor]="'green'">Angenommen</nz-tag>
          <nz-tag *ngIf="!($urlaub | async)?.angenommen && !($urlaub | async)?.abgelehnt" [nzColor]="'blue'">Offen</nz-tag>
        </nz-page-header-tags>
        <nz-page-header-extra>
          <nz-space>
            <button
              *nzSpaceItem
              nz-button
              nzDanger
              [nzLoading]="$loading | async"
              [disabled]="!($urlaub | async)?.id || ($loading | async) || (($urlaub | async)?.angenommen && !(auth.$isUrlaubsAdmin | async))"
              nz-popconfirm
              nzPopconfirmTitle="Wirklich löschen?"
              [nzIcon]="DeleteIcon"
              (nzOnConfirm)="removeUrlaub()"
            >
              <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
              Löschen
              <ng-template #DeleteIcon>
                <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
              </ng-template>
            </button>
          </nz-space>
        </nz-page-header-extra>
      </nz-page-header>
    </ng-template>
    <ng-template #UrlaubModalContent>
      <nz-row>
        <fa-kt-urlaub [style.width]="'100%'" [value]="{ urlaub: ($urlaub | async) }" (finished)="openUrlaub()"></fa-kt-urlaub>
      </nz-row>
    </ng-template>
    <ng-template #UrlaubModalFooter>
      <pbc-meta [value]="($urlaub | async)"></pbc-meta>
    </ng-template>
  </nz-modal>

  <nz-modal [nzTitle]="KrankheitModalTitle" [nzContent]="KrankheitModalContent" [nzFooter]="KrankheitModalFooter" (nzOnCancel)="openKrankheit()" [nzVisible]="!!($krankheit | async)">
    <ng-template #KrankheitModalTitle>
      <nz-page-header [nzTitle]="KrankheitModalHeaderTitle">
        <ng-template #KrankheitModalHeaderTitle> <span nz-icon nzType="medicine-box" nzTheme="outline" style="margin-right: 8px"></span> Krankmeldung </ng-template>
        <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
        <nz-page-header-tags *ngIf="($krankheit | async)?.id">
          <nz-tag *ngIf="!($krankheit | async)?.bescheinigt" [nzColor]="'blue'">Offen</nz-tag>
          <nz-tag *ngIf="($krankheit | async)?.bescheinigt" [nzColor]="'green'">Bescheinigt</nz-tag>
        </nz-page-header-tags>
        <nz-page-header-extra>
          <nz-space>
            <button
              *nzSpaceItem
              nz-button
              nzDanger
              [nzLoading]="$loading | async"
              [disabled]="!($krankheit | async)?.id || ($loading | async) || (($krankheit | async)?.bescheinigt && !(auth.$isOM | async))"
              nz-popconfirm
              nzPopconfirmTitle="Wirklich löschen?"
              [nzIcon]="DeleteIcon"
              (nzOnConfirm)="removeKrankheit()"
            >
              <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
              Löschen
              <ng-template #DeleteIcon>
                <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
              </ng-template>
            </button>
          </nz-space>
        </nz-page-header-extra>
      </nz-page-header>
    </ng-template>
    <ng-template #KrankheitModalContent>
      <nz-row>
        <fa-kt-krankheit [style.width]="'100%'" [value]="{ krankheit: ($krankheit | async) }" (finished)="openKrankheit()"></fa-kt-krankheit>
      </nz-row>
    </ng-template>
    <ng-template #KrankheitModalFooter>
      <pbc-meta [value]="($krankheit | async)"></pbc-meta>
    </ng-template>
  </nz-modal>

  <nz-modal
    [nzTitle]="RahmenAnpassungModalTitle"
    [nzContent]="RahmenAnpassungModalContent"
    [nzFooter]="RahmenAnpassungModalFooter"
    (nzOnCancel)="openRahmenAnpassung()"
    [nzVisible]="!!($rahmenAnpassung | async)"
  >
    <ng-template #RahmenAnpassungModalTitle>
      <nz-page-header [nzTitle]="RahmenAnpassungModalHeaderTitle">
        <ng-template #RahmenAnpassungModalHeaderTitle>
          <span nz-icon nzType="clock-circle" nzTheme="outline" style="margin-right: 8px"></span> Erlaubnis für Über/Unterschreitungen des Stundenrahmens</ng-template
        >
        <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
        <nz-page-header-tags *ngIf="($rahmenAnpassung | async)?.id">
          <nz-tag *ngIf="($rahmenAnpassung | async)?.abgelehnt" [nzColor]="'red'">Abgelehnt</nz-tag>
          <nz-tag *ngIf="($rahmenAnpassung | async)?.angenommen" [nzColor]="'green'">Angenommen</nz-tag>
          <nz-tag *ngIf="!($rahmenAnpassung | async)?.angenommen && !($rahmenAnpassung | async)?.abgelehnt" [nzColor]="'blue'">Offen</nz-tag>
        </nz-page-header-tags>
        <nz-page-header-extra>
          <nz-space>
            <button
              *nzSpaceItem
              nz-button
              nzDanger
              [nzLoading]="$loading | async"
              [disabled]="!($rahmenAnpassung | async)?.id || ($loading | async) || (($rahmenAnpassung | async)?.angenommen && !(auth.$isOM | async) && !(auth.$isPartner | async))"
              nz-popconfirm
              nzPopconfirmTitle="Wirklich löschen?"
              [nzIcon]="DeleteIcon"
              (nzOnConfirm)="removeRahmenAnpassung()"
            >
              <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
              Löschen
              <ng-template #DeleteIcon>
                <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
              </ng-template>
            </button>
          </nz-space>
        </nz-page-header-extra>
      </nz-page-header>
    </ng-template>
    <ng-template #RahmenAnpassungModalContent>
      <nz-row>
        <fa-kt-rahmen-anpassung [style.width]="'100%'" [value]="{ rahmenAnpassung: ($rahmenAnpassung | async) }" (finished)="openRahmenAnpassung()"></fa-kt-rahmen-anpassung>
      </nz-row>
    </ng-template>
    <ng-template #RahmenAnpassungModalFooter>
      <pbc-meta [value]="($rahmenAnpassung | async)"></pbc-meta>
    </ng-template>
  </nz-modal>
</nz-content>
