import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'pbc-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
})
export class FileUploadComponent implements OnInit {
  @Input() icon: string = 'file';
  @Input() text: string | undefined;
  @Input() hint: string | undefined;
  @Input() multi = false;
  @Output() uploaded = new EventEmitter<File>();

  fileList: NzUploadFile[] = [];
  file: File | null = null;
  onChange: Function | null = null;
  onError: Function | null = null;

  constructor(private host: ElementRef<HTMLInputElement>) {}

  ngOnInit(): void {}

  writeValue(value: null) {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnError(fn: Function) {
    this.onError = fn;
  }

  beforeUpload = () => false;

  registerOnTouched(fn: Function) {}

  async handleChange(info: NzUploadChangeParam): Promise<void> {
    if (info.file?.originFileObj) {
      this.file = info.file.originFileObj;
      if (!this.multi) {
        this.fileList = [];
        if (this.file) this.fileList.push(info.file);
        this.uploaded.emit(this.file);
      }
      this.onChange(this.file);
    }
  }
}
