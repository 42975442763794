<nz-select
  class="select-value"
  (nzOnSearch)="search($event)"
  (keyup.enter)="create()"
  (ngModelChange)="setValue($event)"
  [ngModel]="_value"
  [nzMode]="mode"
  [nzDisabled]="disabled"
  [nzAllowClear]="!required"
  nzShowSearch
  [nzSize]="size"
  [nzCustomTemplate]="View"
  [nzMaxTagCount]="3"
  [nzMaxTagPlaceholder]="MAX_OVERLOAD"
  [style.minWidth]="width"
  [nzPlaceHolder]="placeholder"
  [nzNotFoundContent]="NotFound"
>
  <ng-template #View let-selected>
    <nz-avatar
      *ngIf="(selected.nzValue | type: (filtered$ | async) : 'avatar') || ($avatar | async) | safe"
      [nzSrc]="selected.nzValue | type: (filtered$ | async) : 'avatar' | safe: 'url'"
      [nzSize]="20"
      nzIcon="user"
      style="margin-right: 6px"
    ></nz-avatar>
    <i
      *ngIf="selected.nzValue | type: (filtered$ | async) : 'icon'"
      nz-icon
      [style.color]="selected.nzValue | type: (filtered$ | async) : 'color'"
      style="margin-right: 6px"
      [style.marginTop]="size !== 'small' ? '4px' : ''"
      [nzType]="selected.nzValue | type: (filtered$ | async) : 'icon'"
      [style.color]="selected.nzValue | type: (filtered$ | async) : 'color'"
    ></i>
    <label nz-typography [style.color]="selected.nzValue | type: (filtered$ | async) : 'color'" style="margin-right: 6px"> {{ selected.nzLabel }} </label>
  </ng-template>
  <nz-option *ngFor="let option of filtered$ | async" [nzLabel]="option.label!" [nzValue]="option.value" [nzHide]="option.disabled" nzCustomContent>
    <nz-avatar *ngIf="option.avatar || ($avatar | async)" [nzSrc]="option.avatar!" [nzSize]="20" [nzText]="getMitarbeiterText(option.label)" style="margin-right: 6px"></nz-avatar>
    <i *ngIf="option.icon" nz-icon [nzType]="option.icon" [style.color]="option.color"></i>
    <label nz-typography [style.color]="option.color || ''"> {{ option.label }} </label>
  </nz-option>
</nz-select>
<ng-template #MAX_OVERLOAD let-selectedList>& {{ selectedList.length }} mehr</ng-template>
<ng-template #NotFound>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="NotFoundContent" [nzNotFoundFooter]="NotFoundFooter"></nz-empty>
  <ng-template #NotFoundContent>
    <span>
      Keine Daten.
      <a *ngIf="creatable && creating">Zum Erstellen ENTER drücken</a>
      <span *ngIf="creatable && creating">oder</span>
    </span>
  </ng-template>
  <ng-template #NotFoundFooter>
    <button *ngIf="creatable && creating" nz-button nzType="primary" (click)="create()">Hinzufügen</button>
  </ng-template>
</ng-template>
