import { orderBy } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');

import { Component, Inject, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { BehaviorSubject, combineLatest, interval, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { APP_CONFIG, AppEnvironment, BaseComponent, BroadcastService, getEventFromSitemap, OfflineStoreService, ResolverLoadingService, SecretService } from 'pbc.angular';

import {
  BesichtigungenService,
  HeuteService,
  IchService,
  KonstantesService,
  MitarbeiterService,
  ObjekteService,
  PostTagCommandService,
  PostZeitCommandService,
  ProjekteSelectService,
  ProjekteService,
  ReisekostenabrechnungenService,
  RollesService,
  StandortsService,
  TourenSelectService,
  TourenService,
} from 'fa-kt.angular';
import { IBesichtigung, ITag, IZeit } from 'fa-kt.types';
import { toInitials } from 'pbc.functions';
import { ISecrets, ISelection } from 'pbc.types';
import { environment } from '../environments';

interface IBesichtigungSuggestion extends IBesichtigung {
  kunde?: string;
}

interface IZeitEintrag extends IZeit {
  entry: string;
  gespeichert?: boolean;
  minuten: number;
}

@Component({
  selector: 'fa-kt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  isCollapsed = true;
  $menu = new BehaviorSubject<{ url: string[]; emoji: string; title: string; children: { url: string[]; emoji: string; title: string }[] }[]>([]);

  date = new Date();
  isToday = true;
  tag$ = new BehaviorSubject<ITag | undefined>(undefined);
  pausiert$ = new BehaviorSubject<boolean>(false);
  zeit$ = new BehaviorSubject<string | undefined>(undefined);
  working$ = new BehaviorSubject<string | undefined>(undefined);
  pausing$ = new BehaviorSubject<string | undefined>(undefined);
  stundenProTag$ = new BehaviorSubject<number>(8);
  timeMenu = false;
  zuordnung = false;

  zeitArt: string | undefined;
  zeitUnterart$ = new BehaviorSubject<string | undefined>(undefined);
  kunde: string | undefined;
  projekt: string | undefined;

  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxis: boolean = true;
  yAxisTickFormatting = (val: number) => {
    const start = moment(this.tag$.getValue()?.start);
    return start.add(val, 'minutes').format('HH:mm');
  };
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxis: boolean = true;
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  animations: boolean = true;
  customColors: { [name: string]: string } = {};
  zeitColors = (name) => {
    return (
      {
        ...this.customColors,
        Arbeit: '#52c41a',
        Pause: '#b37feb',
      }[name] || '#74E291'
    );
  };
  zeitArten$ = new BehaviorSubject<ISelection[]>([]);
  projekte$ = new BehaviorSubject<ISelection[]>([]);
  assignments$ = new BehaviorSubject<any[]>([]);
  zeiten$ = new BehaviorSubject<IZeitEintrag[]>([]);
  besichtigungen$ = new BehaviorSubject<IBesichtigungSuggestion[]>([]);
  eingetragen$ = new BehaviorSubject<number>(0);
  gearbeitet$ = new BehaviorSubject<number>(0);

  selectsByMinuten: Array<{ minuten: number; name: string; disabled: boolean }> = [
    {
      minuten: 10,
      name: '10 Minuten',
      disabled: false,
    },
    {
      minuten: 15,
      name: '15 Minuten',
      disabled: false,
    },
    {
      minuten: 20,
      name: '20 Minuten',
      disabled: false,
    },
    {
      minuten: 30,
      name: '30 Minuten',
      disabled: false,
    },
    {
      minuten: 45,
      name: '45 Minuten',
      disabled: false,
    },
    {
      minuten: 60,
      name: '1 Stunde',
      disabled: false,
    },
    {
      minuten: 90,
      name: '1½ Stunden',
      disabled: false,
    },
  ];
  pausen: undefined | { index?: number; start: Date; ende: Date } = undefined;

  selectsByStunden: Array<{ minuten: number; name: string; disabled: boolean }> = [120, 180, 240, 300, 360, 420, 480].map((minuten) => ({
    minuten,
    name: minuten / 60 + ' Stunden',
    disabled: false,
  }));

  rest = 0;
  addStunden = 0;
  addMinuten = 0;
  kommentar = '';

  constructor(
    @Inject(APP_CONFIG) public environment: AppEnvironment,
    private swUpdate: SwUpdate,
    public offlineCache: OfflineStoreService,
    public broadcast: BroadcastService,
    public resolverLoader: ResolverLoadingService,
    public secrets: SecretService,
    public ich: IchService,
    public mitarbeiter: MitarbeiterService,
    public rollen: RollesService,
    public konstanten: KonstantesService,
    public reisekostenabrechnungen: ReisekostenabrechnungenService,
    public projekte: ProjekteService,
    public projekteSelect: ProjekteSelectService,
    public objekte: ObjekteService,
    public touren: TourenService,
    public besichtigungen: BesichtigungenService,
    public tourenSelect: TourenSelectService,
    public standorts: StandortsService,
    public postZeit: PostZeitCommandService,
    public postTag: PostTagCommandService,
    public heute: HeuteService,
  ) {
    super();
    this.auth.$id.subscribe(async (id) => {
      this.monitoring.setUser(id);
      if (!id) return;
      const [SECRETS] = await Promise.all([
        this.http.get<ISecrets>('secrets'),
        this.ich.request({ id }),
        this.mitarbeiter.request({}),
        this.rollen.request({}),
        this.standorts.request({}),
        this.konstanten.request({}),
        this.objekte.request({}),
        this.touren.request({}),
        this.projekte.request({}),
        this.projekteSelect.request({}),
        this.besichtigungen.request({ person: id, datum: this.date }),
        this.tourenSelect.request({ mitarbeiter: id }),
        this.postZeit.prepare(),
        this.heute.request({ mitarbeiter: id }),
        this.offlineCache.sync(),
      ]);
      this.secrets.$secrets.next(SECRETS);
    });
    this.ich.result$.subscribe((ich) => {
      const LOCAL_DEV_ADMIN = !this.environment.production && false;
      this.auth.$email.next(ich?.email || null);
      this.auth.$displayName.next(ich?.displayName || null);
      this.auth.$avatar.next(ich?.photo || null);
      this.auth.$groups.next(ich?.groups || []);
      this.auth.$isFinanzen.next(LOCAL_DEV_ADMIN || ich?.finanzen || false);
      this.auth.$isAdmin.next(LOCAL_DEV_ADMIN || ich?.isAdmin || false);
      this.auth.$isOM.next(LOCAL_DEV_ADMIN || ich?.isAdmin || ich?.isOM || ich?.groups.includes(environment.ids.om) || false);
      this.auth.$isPartner.next(LOCAL_DEV_ADMIN || ich?.isAdmin || ich?.isPartner || ich?.groups.includes(environment.ids.partner) || false);
      this.auth.$isUrlaubsAdmin.next(LOCAL_DEV_ADMIN || ich?.isAdmin || ich?.isPartner || ich?.groups.includes(environment.ids.partner) || false);
      this.auth.$routes.next(ich?.authorizedRoutes || []);
    });
    this.mitarbeiter.response$.subscribe((response) => {
      if (!response) return;
      this.meta.users$.next(
        orderBy(
          response.mitarbeiter.map((row) => ({
            value: row.mitarbeiter.id,
            label: `${row.mitarbeiter.anzeigename} (${row.mitarbeiter.email})`,
            disabled: row.mitarbeiter.inaktiv,
            avatar: row.mitarbeiter.bild,
            initialen: toInitials(row.mitarbeiter.anzeigename),
            filter: row.mitarbeiter.standort,
            rolle: row.mitarbeiter.rollen,
            aktiv: !row.mitarbeiter.inaktiv,
          })),
          [({ aktiv }) => !aktiv, ({ label }) => label],
        ),
      );
    });
    this.rollen.response$.subscribe((response) => {
      if (!response) return;
      this.meta.groups$.next(
        response.rolles.map((row) => ({
          value: row.id,
          label: row.name,
          icon: row.isAdmin ? 'unlock' : undefined,
        })),
      );
    });
  }

  override async ngOnInit() {
    super.ngOnInit();
    if (!isDevMode()) {
      this.swUpdate.versionUpdates.subscribe(async (evt) => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.debug(`Downloading new app version: ${evt.version.hash}`);
            this.message.success('Neue Version');
            break;
          case 'VERSION_READY':
            console.debug(`Current app version: ${evt.currentVersion.hash}`);
            console.debug(`New app version ready for use: ${evt.latestVersion.hash}`);
            await this.swUpdate.activateUpdate();
            location.reload();
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });
      await this.swUpdate.checkForUpdate();
    }
    await this.auth.init();

    this.broadcast.$events
      .pipe(
        map((eventName) => getEventFromSitemap(this.sitemap, eventName)),
        filter((event) => event && event.translation && event.translation.length > 0),
      )
      .subscribe((event) => this.message.success(event.translation));

    this.subscriptions.push(
      ...[
        this.broadcast.$events.subscribe((eventName) => console.debug('broadcast', eventName)),
        this.$menu.subscribe((menu) => {
          menu
            .slice()
            .reverse()
            .forEach((section) => {
              if (section.url && section.url.length > 0) {
                this.actions.subscribe({
                  key: 'Zu ' + section.title,
                  action: () => this.router.navigate(section.url).catch(),
                });
              }
              section.children
                .slice()
                .reverse()
                .forEach((page) => {
                  this.actions.subscribe({
                    key: 'Zu ' + section.title + ' → ' + page.title,
                    action: () => this.router.navigate(page.url).catch(),
                  });
                });
            });
        }),
        this.auth.$routes.subscribe((routes) => {
          this.$menu.getValue().forEach((section) => {
            if (section.url && section.url.length > 0) {
              this.actions.unsubscribe('Zu ' + section.title);
            }
            section.children.forEach((page) => {
              this.actions.unsubscribe('Zu ' + section.title + ' → ' + page.title);
            });
          });

          const admin = this.auth.$isAdmin.getValue();
          const menu = [];

          const dashboard = { ...this.sitemap.PROJEKTE.Pages.DASHBOARD, children: [] };
          if (dashboard && (admin || this.auth.access(dashboard.url.join('/'), routes))) {
            menu.push(dashboard);
          }
          const projekte = { ...this.sitemap.PROJEKTE.Pages.PROJEKTE, children: [] };
          if (projekte && (admin || this.auth.access(projekte.url.join('/'), routes))) {
            menu.push(projekte);
          }
          const touren = { ...this.sitemap.BESICHTIGUNGEN.Pages.TOUREN, children: [] };
          if (touren && (admin || this.auth.access(touren.url.join('/'), routes))) {
            menu.push(touren);
          }
          const finanzen = { ...this.sitemap.FINANZEN.Pages.FINANZEN, children: [] };
          if (finanzen && (admin || this.auth.access(finanzen.url.join('/'), routes))) {
            menu.push(finanzen);
          }
          const kunden = { ...this.sitemap.KUNDEN.Pages.KUNDEN, children: [] };
          if (kunden && (admin || this.auth.access(kunden.url.join('/'), routes))) {
            menu.push(kunden);
          }
          const markt = { ...this.sitemap.MARKT.Pages.MARKT_BERICHTE, children: [] };
          if (markt && (admin || this.auth.access(markt.url.join('/'), routes))) {
            menu.push(markt);
          }
          const formulare = { ...this.sitemap.FORMULARE.Pages.FORMULARE, children: [] };
          if (formulare && (admin || this.auth.access(formulare.url.join('/'), routes))) {
            menu.push(formulare);
          }
          const texte = { ...this.sitemap.TEXTE.Pages.VORLAGEN, children: [] };
          if (texte && (admin || this.auth.access(texte.url.join('/'), routes))) {
            menu.push(texte);
          }
          const zeiten = { ...this.sitemap.ZEITEN.Pages.ZEITEN, children: [] };
          if (zeiten && (admin || this.auth.access(zeiten.url.join('/'), routes))) {
            menu.push(zeiten);
          }
          const auswertungen = { ...this.sitemap.AUSWERTUNGEN.Pages.AUSWERTUNGEN, children: [] };
          if (auswertungen && (admin || this.auth.access(auswertungen.url.join('/'), routes))) {
            menu.push(auswertungen);
          }
          this.$menu.next(menu);
        }),

        this.heute.response$.subscribe((heute) => {
          this.stundenProTag$.next(heute?.minuten.stundentag || 8);
          this.tag$.next(heute?.tag);
        }),
        combineLatest([this.tag$, interval(1000)]).subscribe(([tag]) => {
          const pausiert = (tag?.pausen || []).some(({ ende }) => !ende);
          this.pausiert$.next(pausiert);
          const today = moment(this.date);
          const now = moment().set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
          const pause = (tag?.pausen || []).map(({ start, ende }) => moment(ende || now).diff(start, 'seconds')).reduce((sum, pause) => sum + pause, 0);
          if (pausiert) {
            this.pausing$.next(moment.utc(pause * 1000).format('HH:mm:ss'));
            this.working$.next(undefined);
          } else if (tag && !tag.zugeordnet) {
            const today = moment(this.date);
            const now = moment().set('year', today.year()).set('month', today.month()).set('date', today.date());
            this.working$.next(moment.utc((now.diff(tag.start, 'seconds') - pause) * 1000).format('HH:mm:ss'));
            this.pausing$.next(undefined);
          } else {
            this.pausing$.next(undefined);
            this.working$.next(undefined);
          }
        }),

        this.postZeit.shapes$.subscribe((shapes) => {
          if (!shapes || this.zeitUnterart$.getValue()) return;
          this.zeitArten$.next(shapes['zeit.zeitArt'].filter(({ value }) => value.toString() !== '4'));
          const shape = shapes['zeit.zeitUnterart']?.find(({ value }) => value.toString() === '56');
          this.zeitUnterart$.next(shape?.value);
          this.zeitArt = shape?.filter as string;
        }),
        combineLatest([this.projekteSelect.selection$, this.auth.$id]).subscribe(([selection, person]) => {
          //  , this.zeitUnterart$
          if (!selection) return;
          this.projekte$.next(
            orderBy(
              selection.filter(({ aktiv }) => !!aktiv), // gutachten, besichtigungen, pruefung
              (s) => String(!(!person || s.mitarbeiter?.includes(person))),
            ),
          );
        }),
        this.projekte$.subscribe((projekte) => {
          if (!projekte?.length || this.projekt) return;
          this.projekt = projekte[0].value;
          this.kunde = projekte[0].filter as string;
        }),
        this.heute.result$.subscribe((result) => {
          if (!result?.zeiten) return;
          this.zeiten$.next([
            ...this.zeiten$.getValue().filter(({ gespeichert }) => !gespeichert),
            ...result.zeiten.map(({ zeit }) => ({ ...zeit, gespeichert: true, entry: Date.now().toString(), minuten: moment(zeit.ende).diff(zeit.datum, 'minutes') })),
          ]);
        }),
        combineLatest([this.besichtigungen.result$, this.zeiten$]).subscribe(([besichtigungen, zeiten]) => {
          if (!besichtigungen?.besichtigungen?.length) return;
          const saved = zeiten.map(({ besichtigung }) => besichtigung).filter((id) => !!id);
          this.besichtigungen$.next(
            besichtigungen.besichtigungen
              .map(({ besichtigung, kunde: { id: kunde } }) => ({
                ...besichtigung,
                kunde,
                fahrtVon: moment(besichtigung.fahrtVon).set('year', moment(this.date).year()).set('month', moment(this.date).month()).set('date', moment(this.date).date()).toDate(),
                bis: moment(besichtigung.bis).set('year', moment(this.date).year()).set('month', moment(this.date).month()).set('date', moment(this.date).date()).toDate(),
              }))
              .filter(({ id }) => !saved.includes(id)),
          );
        }),
        combineLatest([this.tag$, this.zeiten$]).subscribe(([tag, zeiten]) => {
          const assignments = this.assignments$.getValue();
          if (!tag || !assignments[1]?.series) return;
          const hinzugefügt = zeiten.map(({ datum, ende }) => moment(ende).diff(datum, 'minutes')).reduce((sum, minutes) => sum + minutes, 0);
          this.rest = (tag.minuten?.arbeit || 0) - hinzugefügt;
          this.selectsByMinuten = this.selectsByMinuten.map((s) => ({ ...s, disabled: s.minuten > this.rest }));
          this.selectsByStunden = this.selectsByStunden.map((s) => ({ ...s, disabled: s.minuten > this.rest }));
          this.eingetragen$.next(hinzugefügt);

          zeiten = orderBy(zeiten, ({ datum }) => new Date(datum).getTime()).reduce((arr, zeit) => {
            const datum = arr[arr.length - 1]?.ende || new Date(tag.start);
            const ende = moment(datum).add(zeit.minuten, 'minutes').toDate();
            return [...arr, { ...zeit, datum, ende }];
          }, []);

          const series = [];
          for (const zeit of zeiten) {
            const previous = series.length ? series[series.length - 1] : undefined;
            if (previous?.extra.ende) {
              const diff = moment(previous.extra.ende).diff(zeit.datum, 'minutes');
              zeit.datum = moment(zeit.datum).add(diff, 'minutes').toDate();
              zeit.ende = moment(zeit.datum).add(zeit.minuten, 'minutes').toDate();
            } else {
              zeit.datum = new Date(zeit.datum);
              zeit.ende = new Date(zeit.ende);
            }

            const value = zeit.minuten;
            const zeitArt = this.postZeit.getZeitZeitArt(zeit.zeitArt)?.label || '';
            const zeitUnterart = this.postZeit.getZeitZeitUnterart(zeit.zeitUnterart)?.label || '';
            const kunde = this.kunde ? this.postZeit.getZeitKunde(zeit.kunde)?.label || '' : '';
            const projekt = this.projekt ? this.projekte$.getValue().find(({ value }) => value === zeit.projekt)?.label || '' : '';

            const name = `${zeit.gespeichert ? 'Gespeichert ✅: ' : ''}${zeitUnterart} (${zeitArt})${projekt ? ` an ${projekt}` : ''}${kunde ? ` für ${kunde}` : ''}${
              zeit.kommentar ? `:"${zeit.kommentar}"` : ''
            }`;
            if (zeit.gespeichert) this.customColors[name] = '#201658';

            const id = Date.now().toString();

            const entry = {
              id,
              name,
              value,
              extra: {
                entry: zeit.entry,
                readonly: zeit.gespeichert,
                start: new Date(zeit.datum),
                ende: new Date(zeit.ende),
              },
            };

            const pausen = (tag.pausen || []).filter((p) => moment(p.start).isBetween(zeit.datum, zeit.ende));
            if (!pausen.length) series.push(entry);
            else {
              series.push(
                ...pausen.reduce((arr, { start, ende }) => {
                  const base = !arr.length ? entry : { id: Date.now(), ...arr.pop() };
                  const index = (tag.pausen || []).indexOf((tag.pausen || []).find(({ start: pause }) => new Date(pause).getTime() === new Date(start).getTime()));
                  const vorher = { ...base, value: moment(start).diff(base.extra.start, 'minutes'), extra: { ...base.extra, start: new Date(base.extra.start), ende: new Date(start) } };
                  const pausiert = moment(ende).diff(start, 'minutes');
                  const pause = {
                    id: 'pause-' + (index + 1),
                    name: 'Pause',
                    value: pausiert,
                    extra: {
                      index,
                      start: new Date(start),
                      ende: new Date(ende),
                    },
                  };
                  const next = moment(base.extra.ende).add(pausiert, 'minutes').toDate();
                  const nachher = {
                    id: Date.now(),
                    ...base,
                    value: moment(next).diff(ende, 'minutes'),
                    extra: { ...base.extra, start: new Date(ende), ende: next },
                  };
                  return [...arr, vorher, pause, nachher];
                }, []),
              );
            }
          }

          assignments[1].series = orderBy(series, ({ extra }) => extra.start.getTime());
          this.assignments$.next([...assignments]);
        }),
      ],
    );
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  async selectedDate(date: Date) {
    this.assignments$.next([]);
    this.date = new Date(date);
    this.isToday = moment(this.date).format('DD.MM.yyyy') === moment().format('DD.MM.yyyy');
    await Promise.all([this.heute.request({ ...this.heute.payload$.getValue(), date }), this.besichtigungen.request({ ...this.besichtigungen.payload$.getValue(), datum: date })]);
  }

  async start(open = false) {
    this.loading$.next(true);
    await this.postTag
      .request({
        tag: {
          mitarbeiter: this.auth.$id.getValue(),
          start: this.date,
        },
      })
      .then(({ tag }) => this.tag$.next(tag))
      .then(() => {
        if (open || !this.isToday) this.begin();
      })
      .finally(() => this.loading$.next(false));
  }

  async pause() {
    const tag = this.tag$.getValue();
    if (!tag || (tag.pausen || []).some(({ ende }) => !ende)) return this.message.warning('Kein pausierbarer Tag');
    this.loading$.next(true);
    tag.pausen = tag.pausen || [];
    const today = moment(this.date);
    const now = moment().set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
    tag.pausen.push({ start: now });
    await this.postTag
      .request({
        tag,
      })
      .finally(() => this.loading$.next(false));
    return undefined;
  }

  async resume() {
    const tag = this.tag$.getValue();
    if (!tag || !(tag.pausen || []).some(({ ende }) => !ende)) return this.message.warning('Kein pausierter Tag');
    this.loading$.next(true);
    const today = moment(this.date);
    const now = moment().set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
    tag.pausen.find(({ ende }) => !ende).ende = now;
    await this.postTag
      .request({
        tag,
      })
      .finally(() => this.loading$.next(false));
    return undefined;
  }

  assign() {
    const tag = this.tag$.getValue();
    if (!tag) return this.message.warning('Kein Tag');
    this.loading$.next(true);
    this.begin();
    return undefined;
  }

  hasKundenEintragung(zeitUnterart?: string) {
    return !['8c92441d-c58f-4033-9131-332c829b731c'].includes(zeitUnterart?.toString());
  }

  private begin() {
    const tag = this.tag$.getValue();
    if (!tag) return;

    const today = moment(this.date);
    const now = moment().set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
    tag.ende = tag.ende || now;

    tag.pausen = tag.pausen || [];
    const pause = tag.pausen.map(({ start, ende }) => moment(ende || now).diff(start, 'minutes')).reduce((sum, pause) => sum + pause, 0);
    if (tag.pausen.find(({ ende }) => !ende)) tag.pausen.find(({ ende }) => !ende).ende = now;

    tag.minuten = {
      pause,
      arbeit: moment(tag.ende).diff(tag.start, 'minutes') - pause,
    };

    this.assignments$.next([
      {
        id: 'day',
        name: moment(tag.start).format('DD.MM.yy'),
        series: orderBy(
          [
            {
              id: 'arbeit-0',
              name: 'Arbeit',
              value: moment(tag.pausen[0]?.start || tag.ende).diff(tag.start, 'minutes'),
              extra: {
                start: new Date(tag.start),
                ende: new Date(tag.pausen[0]?.start || tag.ende),
              },
            },
            ...tag.pausen
              .map(({ start, ende }, index) =>
                [
                  {
                    id: 'pause-' + (index + 1),
                    name: 'Pause',
                    value: moment(ende || now).diff(start, 'minutes'),
                    extra: {
                      index,
                      start: new Date(start),
                      ende: new Date(ende),
                    },
                  },
                  ende
                    ? {
                        id: 'arbeit-' + (index + 1),
                        name: 'Arbeit',
                        value: moment(tag.pausen[index + 1]?.start || tag.ende).diff(ende, 'minutes'),
                        extra: {
                          start: new Date(ende),
                          ende: new Date(tag.pausen[index + 1]?.start || tag.ende),
                        },
                      }
                    : undefined,
                ].filter((v) => !!v),
              )
              .flat(),
          ],
          (e) => e.extra.start.getTime(),
        ),
        extra: {},
      },
      {
        id: 'work',
        name: 'Zugeordnet',
        series: [],
        extra: {},
      },
    ]);
    this.tag$.next(tag);
    this.zuordnung = true;
    this.loading$.next(false);
  }

  setPause(index: number, pause: { start: Date; ende: Date }) {
    this.pausen = { ...pause, index };
  }

  addPause() {
    const tag = this.tag$.getValue();
    if (!tag || this.pausen) return;
    this.pausen = { start: tag.ende, ende: moment(tag.ende).add(15, 'minutes').toDate() };
  }

  removePause(index: number) {
    const tag = this.tag$.getValue();
    if (!tag) return;
    const pausen = tag.pausen || [];
    pausen.splice(index, 1);
    const today = moment(this.date);
    const now = moment().set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
    const pause = (tag.pausen || []).map(({ start, ende }) => moment(ende || now).diff(start, 'minutes')).reduce((sum, pause) => sum + pause, 0);
    tag.minuten = {
      pause,
      arbeit: moment(tag.ende || now).diff(tag.start, 'minutes') - pause,
    };
    this.assignments$.next([]);
    this.tag$.next(tag);
    this.changeRef.detectChanges();
    this.begin();
  }

  savePause() {
    const tag = this.tag$.getValue();
    if (!tag || !this.pausen) return;
    const pausen = tag.pausen || [];
    if (typeof this.pausen.index === 'number' && pausen[this.pausen.index]) pausen[this.pausen.index] = { start: new Date(this.pausen.start), ende: new Date(this.pausen.ende) };
    else pausen.push({ start: new Date(this.pausen.start), ende: new Date(this.pausen.ende) });
    const today = moment(this.date);
    const now = moment().set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
    const pause = (tag.pausen || []).map(({ start, ende }) => moment(ende || now).diff(start, 'minutes')).reduce((sum, pause) => sum + pause, 0);
    tag.minuten = {
      pause,
      arbeit: moment(tag.ende || now).diff(tag.start, 'minutes') - pause,
    };
    this.assignments$.next([]);
    this.tag$.next(tag);
    this.changeRef.detectChanges();
    this.pausen = undefined;
    this.begin();
  }

  setStart(start: Date) {
    const tag = this.tag$.getValue();
    if (!tag) return;
    const today = moment(this.date);
    const now = moment().set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
    tag.start = moment(start).set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
    const pause = (tag.pausen || []).map(({ start, ende }) => moment(ende || now).diff(start, 'minutes')).reduce((sum, pause) => sum + pause, 0);
    tag.minuten = {
      pause,
      arbeit: moment(tag.ende || now).diff(tag.start, 'minutes') - pause,
    };
    this.assignments$.next([]);
    this.tag$.next(tag);
    this.begin();
  }

  setEnde(ende: Date) {
    const tag = this.tag$.getValue();
    if (!tag) return;
    const today = moment(this.date);
    tag.ende = moment(ende).set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
    const pause = (tag.pausen || []).map(({ start, ende }) => moment(ende || tag.ende).diff(start, 'minutes')).reduce((sum, pause) => sum + pause, 0);
    tag.minuten = {
      pause,
      arbeit: moment(tag.ende || tag.ende).diff(tag.start, 'minutes') - pause,
    };
    this.assignments$.next([]);
    this.tag$.next(tag);
    this.begin();
  }

  adapt() {
    const tag = this.tag$.getValue();
    if (!tag) return;
    tag.ende = moment(tag.start)
      .add(this.eingetragen$.getValue() + tag.minuten?.pause || 0, 'minutes')
      .toDate();
    this.begin();
    this.tag$.next(tag);
  }

  setOrt(ort: ('HomeOffice' | 'Büro' | 'Unterwegs')[]) {
    const tag = this.tag$.getValue();
    if (!tag) return;
    tag.ort = ort;
    this.tag$.next(tag);
  }

  cancel() {
    const tag = this.tag$.getValue();
    if (tag?.ende && !tag?.zugeordnet) {
      delete tag.ende;
      this.tag$.next(tag);
    }
    this.zuordnung = false;
  }

  addCustom() {
    const minuten = this.addStunden * 60 + this.addMinuten;
    if (minuten < 1) return;
    this.addZeit(minuten);
    this.addStunden = 0;
    this.addMinuten = 0;
  }

  addZeit(value: number) {
    const assignments = this.assignments$.getValue();
    const tag = this.tag$.getValue();
    const datum = new Date(assignments[1]?.series?.length ? orderBy(assignments[1].series, (s) => new Date(s.extra.start).getTime()).shift().extra.ende : tag?.start);
    const ende = moment(datum).add(value, 'minutes').toDate();
    this.zeiten$.next([
      ...this.zeiten$.getValue(),
      {
        minuten: value,
        entry: Date.now().toString(),
        mitarbeiter: this.auth.$id.getValue(),
        datum,
        ende,
        zeitArt: this.zeitArt,
        zeitUnterart: this.zeitUnterart$.getValue(),
        projekt: this.zeitArt?.toString() === '1' ? this.projekt : undefined,
        kunde: this.zeitArt?.toString() === '1' ? this.kunde : undefined,
        kommentar: this.kommentar,
      },
    ]);
    this.kommentar = '';
  }

  addBesichtigung(besichtigung: IBesichtigungSuggestion) {
    const value = moment(besichtigung.bis).diff(besichtigung.fahrtVon, 'minutes');
    const assignments = this.assignments$.getValue();
    const tag = this.tag$.getValue();
    const datum = new Date(assignments[1]?.series?.length ? orderBy(assignments[1].series, (s) => new Date(s.extra.start).getTime()).shift().extra.ende : tag?.start);
    const ende = moment(datum).add(value, 'minutes').toDate();

    this.zeiten$.next([
      ...this.zeiten$.getValue(),
      {
        minuten: value,
        entry: Date.now().toString(),
        mitarbeiter: this.auth.$id.getValue(),
        besichtigung: besichtigung.id,
        datum,
        ende,
        kommentar: '',
        zeitArt: '1',
        zeitUnterart: '2',
        kunde: besichtigung.kunde,
        projekt: besichtigung.projekt,
      },
    ]);
  }

  removeZeit(id: string) {
    this.modal.confirm({
      nzTitle: 'Eintrag entfernen?',
      nzOnOk: () => {
        this.zeiten$.next(this.zeiten$.getValue().filter(({ entry }) => entry !== id));
      },
      nzOkText: 'Entfernen',
      nzOkDanger: true,
      nzWidth: 350,
    });
  }

  async onSelect(event: any) {
    if (!event.extra.readonly && !['pause-', 'arbeit-'].some((type) => event.id?.startsWith(type))) return this.removeZeit(event.extra.entry);
    if (event.id.startsWith('pause-')) return this.setPause(event.extra.index, event.extra as { start: Date; ende: Date });
    return undefined;
  }

  async save() {
    this.loading$.next(true);
    const actions = [];

    const tag = this.tag$.getValue();
    if (tag?.ende && !tag?.zugeordnet) delete tag.ende;
    actions.push(this.postTag.request({ tag }).then(() => this.message.success('Tag wurde zwischengespeichert')));

    const zeit: Array<IZeit> = this.zeiten$
      .getValue()
      .filter(({ gespeichert }) => !gespeichert)
      .map((zeit) => {
        delete zeit.entry;
        return {
          ...zeit,
          stunden: Math.floor(zeit.minuten / 60),
          minuten: zeit.minuten % 60,
        };
      });
    if (zeit.length) actions.push(this.postZeit.request({ zeit }));
    await Promise.all(actions).finally(() => this.loading$.next(false));
    this.zeiten$.next([]);
    this.tag$.next(tag);
    this.zuordnung = false;
    return undefined;
  }

  async submit() {
    this.loading$.next(true);
    const tag = this.tag$.getValue();
    tag.zugeordnet = true;
    const zeit: Array<IZeit> = this.zeiten$
      .getValue()
      .filter(({ gespeichert }) => !gespeichert)
      .map((zeit) => {
        delete zeit.entry;
        return {
          ...zeit,
          stunden: Math.floor(zeit.minuten / 60),
          minuten: zeit.minuten % 60,
        };
      });
    const actions = [];
    if (zeit.length) actions.push(this.postZeit.request({ zeit }));
    if (tag?.mitarbeiter)
      actions.push(
        this.postTag
          .request({
            tag,
          })
          .then(() => this.message.success('Tag wurde abgeschlossen.')),
      );
    await Promise.all(actions).finally(() => this.loading$.next(false));
    this.zeiten$.next([]);
    this.zuordnung = false;
    return undefined;
  }

  setKundeByProjekt(id?: string) {
    const projekt = this.projekte$.getValue().find(({ value }) => value === id);
    this.kunde = (projekt?.filter as string) || undefined;
  }

  setUnterartByArt(id?: string) {
    this.zeitUnterart$.next(this.postZeit.shapes$.getValue()?.['zeit.zeitUnterart']?.find(({ filter }) => filter === id)?.value);
  }

  formatMinutes(minutes: number) {
    minutes = minutes || 0;
    const stunden = Math.floor(minutes / 60);
    const minuten = Math.floor(minutes % 60);
    const format = [];
    if (stunden) format.push(`${stunden} Stunde${stunden === 1 ? '' : 'n'}`);
    if (minuten) format.push(`${minuten} Minute${minuten === 1 ? '' : 'n'}`);
    return format.join(' & ') || '-';
  }

  formatRange(start: Date, ende: Date) {
    return moment.utc(moment(ende).diff(start, 'seconds') * 1000).format('HH:mm');
  }
}
