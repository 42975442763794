import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');

export type SupportedStates = 'hh' | 'sn' | 'be' | 'hb' | 'he';

export interface Feiertag {
  date: Date;
  faktor: number;
  fname?: string;
  all_states?: boolean;
  hh: boolean;
  sn: boolean;
  be: boolean;
  hb: boolean;
  he: boolean;
  comment?: string;
  augsburg?: null;
  katholisch?: null;
}
// https://get.api-feiertage.de/?years=2024,2025,2026&states=hh,sn
export const FEIERTAGE: Feiertag[] = [
  {
    date: '2023-01-01',
    fname: 'Neujahr',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-03-08',
    fname: 'Internationaler Frauentag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '1',
    bb: '0',
    hb: '0',
    hh: '0',
    he: '0',
    mv: '1',
    ni: '0',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '0',
    st: '0',
    sh: '0',
    th: '0',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-04-07',
    fname: 'Karfreitag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-04-10',
    fname: 'Ostermontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-05-01',
    fname: 'Tag der Arbeit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-05-18',
    fname: 'Christi Himmelfahrt',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-05-29',
    fname: 'Pfingstmontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-06-08',
    fname: 'Fronleichnam',
    all_states: '0',
    bw: '1',
    by: '1',
    be: '0',
    bb: '0',
    hb: '0',
    hh: '0',
    he: '1',
    mv: '0',
    ni: '0',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '0',
    st: '0',
    sh: '0',
    th: '0',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-10-03',
    fname: 'Tag der deutschen Einheit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-10-31',
    fname: 'Reformationstag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '0',
    mv: '1',
    ni: '1',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-11-22',
    fname: 'Buß- und Bettag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '0',
    hb: '0',
    hh: '0',
    he: '0',
    mv: '0',
    ni: '0',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '0',
    sh: '0',
    th: '0',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-12-24',
    fname: 'Heiligabend',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-12-25',
    fname: '1. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-12-26',
    fname: '2. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2023-12-31',
    fname: 'Silvester',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-01-01',
    fname: 'Neujahr',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-03-29',
    fname: 'Karfreitag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-04-01',
    fname: 'Ostermontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-05-01',
    fname: 'Tag der Arbeit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-05-09',
    fname: 'Christi Himmelfahrt',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-05-20',
    fname: 'Pfingstmontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-10-03',
    fname: 'Tag der deutschen Einheit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-10-31',
    fname: 'Reformationstag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '0',
    mv: '1',
    ni: '1',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-11-20',
    fname: 'Buß- und Bettag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '0',
    hb: '0',
    hh: '0',
    he: '0',
    mv: '0',
    ni: '0',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '0',
    sh: '0',
    th: '0',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-12-24',
    fname: 'Heiligabend',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-12-25',
    fname: '1. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-12-26',
    fname: '2. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2024-12-31',
    fname: 'Silvester',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-01-01',
    fname: 'Neujahr',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-04-18',
    fname: 'Karfreitag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-04-21',
    fname: 'Ostermontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-05-01',
    fname: 'Tag der Arbeit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-05-29',
    fname: 'Christi Himmelfahrt',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-06-09',
    fname: 'Pfingstmontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-10-03',
    fname: 'Tag der deutschen Einheit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-10-31',
    fname: 'Reformationstag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '0',
    mv: '1',
    ni: '1',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-11-19',
    fname: 'Buß- und Bettag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '0',
    hb: '0',
    hh: '0',
    he: '0',
    mv: '0',
    ni: '0',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '0',
    sh: '0',
    th: '0',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-12-24',
    fname: 'Heiligabend',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-12-25',
    fname: '1. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-12-26',
    fname: '2. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2025-12-31',
    fname: 'Silvester',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-01-01',
    fname: 'Neujahr',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-04-03',
    fname: 'Karfreitag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-04-06',
    fname: 'Ostermontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-05-01',
    fname: 'Tag der Arbeit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-05-14',
    fname: 'Christi Himmelfahrt',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-05-25',
    fname: 'Pfingstmontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-10-03',
    fname: 'Tag der deutschen Einheit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-10-31',
    fname: 'Reformationstag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '0',
    mv: '1',
    ni: '1',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-11-18',
    fname: 'Buß- und Bettag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '0',
    hb: '0',
    hh: '0',
    he: '0',
    mv: '0',
    ni: '0',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '0',
    sh: '0',
    th: '0',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-12-24',
    fname: 'Heiligabend',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-12-25',
    fname: '1. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-12-26',
    fname: '2. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2026-12-31',
    fname: 'Silvester',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-01-01',
    fname: 'Neujahr',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-03-26',
    fname: 'Karfreitag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-03-29',
    fname: 'Ostermontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-05-01',
    fname: 'Tag der Arbeit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-05-06',
    fname: 'Christi Himmelfahrt',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-05-17',
    fname: 'Pfingstmontag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-10-03',
    fname: 'Tag der Deutschen Einheit',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-10-31',
    fname: 'Reformationstag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '0',
    mv: '1',
    ni: '1',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-11-17',
    fname: 'Buß- und Bettag',
    all_states: '0',
    bw: '0',
    by: '0',
    be: '0',
    bb: '0',
    hb: '0',
    hh: '0',
    he: '0',
    mv: '0',
    ni: '0',
    nw: '0',
    rp: '0',
    sl: '0',
    sn: '1',
    st: '0',
    sh: '0',
    th: '0',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-12-24',
    fname: 'Heiligabend',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-12-25',
    fname: '1. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-12-26',
    fname: '2. Weihnachtstag',
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
  {
    date: '2027-12-31',
    fname: 'Silvester',
    halb: true,
    all_states: '1',
    bw: '1',
    by: '1',
    be: '1',
    bb: '1',
    hb: '1',
    hh: '1',
    he: '1',
    mv: '1',
    ni: '1',
    nw: '1',
    rp: '1',
    sl: '1',
    sn: '1',
    st: '1',
    sh: '1',
    th: '1',
    comment: '',
    augsburg: null,
    katholisch: null,
  },
].map((f) => ({
  ...f,
  faktor: f.halb ? 0.5 : 1,
  date: moment(f.date + ' 00:00', 'YYYY-MM-DD HH:mm').toDate(),
  all_states: f.all_states === '1',
  hh: f.hh === '1',
  sn: f.sn === '1',
  be: f.be === '1',
  hb: f.hb === '1',
  he: f.he === '1',
}));

export function isLeapYear(year: number) {
  return new Date(year, 1, 29).getDate() === 29;
}

function getWeekendDays(from: Date, to: Date): number {
  from = new Date(from);
  to = new Date(to);

  if (from.getTime() > to.getTime()) {
    const t = from;
    from = to;
    to = t;
  }

  from = moment(from).startOf('day').toDate();
  to = moment(to).endOf('day').toDate();

  const result = [];
  while (from.getTime() < to.getTime()) {
    if ([6, 7].includes(moment(from).isoWeekday())) result.push(from);
    from = moment(from).add(1, 'day').toDate();
  }

  return result.length;
}

export function getArbeitstage(
  from: Date,
  to: Date,
  state?: SupportedStates,
): {
  days: number;
  feiertage: number;
  weekend: number;
  working: number;
  from: Date;
  to: Date;
} {
  from = new Date(from);
  to = new Date(to);

  if (from.getTime() > to.getTime()) {
    const t = from;
    from = to;
    to = t;
  }

  from = moment(from).startOf('day').toDate();
  to = moment(to).endOf('day').toDate();

  const days = moment(to).diff(from, 'days') + 1;
  const feiertage = FEIERTAGE.filter((f) => (state ? f[state] : f.all_states) && ![6, 7].includes(moment(f.date).isoWeekday()) && moment(f.date).isBetween(from, to)).reduce(
    (s, { faktor }) => s + faktor,
    0,
  );
  const weekend = getWeekendDays(from, to);
  const result = days - weekend - feiertage;
  return {
    from,
    to,
    days,
    feiertage,
    weekend,
    working: Math.max(result, result * -1),
  };
}

export function getLastArbeitstage(today: Date, state: SupportedStates, count = 3): Date[] {
  const dates: Date[] = [];
  let possible = moment(today).subtract(1, 'day').toDate();
  while (dates.length < count) {
    if (isArbeitstag(possible, state)) dates.push(possible);
    possible = moment(possible).subtract(1, 'day').toDate();
  }
  return dates;
}

export function isArbeitstag(date: Date, state?: SupportedStates): boolean {
  return date.getDay() !== 0 && date.getDay() !== 6 && FEIERTAGE.filter((f) => (state ? f[state] : f.all_states) && moment(date).endOf('day').isSame(moment(f.date), 'day')).length === 0;
}
