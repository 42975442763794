import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';
import _, { orderBy } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import 'moment/locale/de';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, MetaService, SITEMAP } from 'pbc.angular';
import { toInitials } from 'pbc.functions';

import { getErfolg, getErfolgColor } from 'fa-kt.functions';
import { IErinnerung, INachZeiten, IProjektAnteil, ITour, IZeitenQueryFilter } from 'fa-kt.types';
import { IDashboardPageResolverResult } from '.';
import { SollIstService } from '../../../auswertungen';
import { PostErinnerungCommandService } from '../../../kommunikation';
import { ZeitenService } from '../../../zeiten/querys';
import { DashboardService } from '../../querys';

interface Erinnerung extends IErinnerung {
  datetime: string;
}

@Component({
  selector: 'fa-kt-dashboard-page',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.css'],
})
export class DashboardPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public _kunde: ISitemapPage;
  public _projekt: ISitemapPage;
  public _tour: ISitemapPage;
  public _durchgang: ISitemapPage;
  public _zeiten: ISitemapPage;

  private $mine = new BehaviorSubject<boolean>(false);
  $erinnerungen = new BehaviorSubject<Erinnerung[]>([]);

  guv$ = new BehaviorSubject<boolean>(false);
  overview$ = new BehaviorSubject<any[]>([]);
  month$ = new BehaviorSubject<any[]>([]);
  project$ = new BehaviorSubject<any[]>([]);
  selected$ = new BehaviorSubject<string>('');

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Monate';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Anteile';
  animations: boolean = true;

  mainCustomColors = (value) => {
    return this.mainColors[value];
  };
  oneCustomColors = (value) => {
    return this.oneColors[value];
  };
  mainColors = {};
  oneColors = {
    Stunden: 'green',
    Besichtigung: '#ff8000',
    Plausibilisierung: '#ffff00',
    Vorbereitung: '#8000ff',
    Gutachten: '#8000ff',
    Gutachtenerstellung: '#8000ff',
    Gewinn: '#73d13d',
    Verlust: '#fa541c',
  };
  monthLabel: string;
  projektLabel: string | undefined;
  selectedMitarbeiter: string | undefined;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public meta: MetaService,
    public files: FileService,
    public dashboard: DashboardService,
    public postErinnerung: PostErinnerungCommandService,
    public sollIst: SollIstService,
    public zeiten: ZeitenService,
  ) {
    this.page = this.sitemap.PROJEKTE.Pages.DASHBOARD;
    this._kunde = sitemap.KUNDEN.Pages.KUNDE;
    this._projekt = sitemap.PROJEKTE.Pages.PROJEKT;
    this._tour = sitemap.BESICHTIGUNGEN.Pages.TOUR;
    this._durchgang = sitemap.FORMULARE.Pages.DURCHGANG;
    this._zeiten = sitemap.ZEITEN.Pages.ZEITEN;
    /* << constructor    */

    /*    constructor >> */
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IDashboardPageResolverResult = data.querys;
          /* << resolver    */
          /*    resolver >> */
        }),
        /* << subscriptions    */
        combineLatest([this.dashboard.result$, this.$mine]).subscribe(([result, mine]) => {
          if (!result || !result.erinnerungen) {
            return;
          }
          let erinnerungen;
          if (mine) {
            erinnerungen = result.erinnerungen.filter((e) => e.mitarbeiter?.includes(this.auth.$id.getValue() as string));
          } else {
            erinnerungen = result.erinnerungen;
          }
          this.$erinnerungen.next(
            orderBy(erinnerungen, 'am')
              .reverse()
              .map((erinnerung) => ({
                ...erinnerung,
                datetime: formatDistanceToNow(new Date(erinnerung._createdAt as Date), { includeSeconds: false, locale: de }),
              })),
          );
        }),
        combineLatest([this.sollIst.result$, this.guv$]).subscribe(([result, guv]) => {
          if (!result) return;
          this.selected$.next('');
          this.project$.next([]);
          this.month$.next([]);
          const data = _.orderBy(
            Object.entries(result.letztesJahr || {})
              .filter((e) => !isNaN(Number(e[0])))
              .filter((e) => Number(e[0]) < 24)
              .map(([key, projekte]) => {
                const start = moment(new Date())
                  .add((Number(key) || 1) * -1, 'months')
                  .startOf('month')
                  .locale('de')
                  .toDate();
                const ende = moment(start).endOf('month').toDate();
                const monthName = moment(start).format('MMM yyyy');
                const series = projekte
                  .filter(({ anteil }) => anteil > 0)
                  .map((projekt) => {
                    const erfolg = getErfolg(projekt);
                    const name = projekt.label + ' (' + erfolg + ' %)';
                    this.mainColors[name] = getErfolgColor(erfolg);
                    return {
                      name,
                      value: (guv ? projekt.anteil - projekt.nachStunden : projekt.anteil) || 0,
                      id: projekt.projekt,
                      extra: {
                        projekt,
                        month: {
                          label: monthName,
                          rows: projekte,
                          start,
                          ende,
                        },
                      },
                    };
                  });
                return {
                  id: key,
                  name: monthName,
                  series,
                  extra: {
                    start,
                    ende,
                  },
                };
              })
              .filter((f) => f.series.length > 0),
            (row) => Number(row.id),
          ).reverse();
          this.overview$.next(data);
        }),
        this.auth.$id.subscribe((id) => (this.selectedMitarbeiter = this.sollIst.payload$.getValue()?.mitarbeiter || id)),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setDashboard(id: string) {
    /* << set-dashboard    */
    /*    set-dashboard >> */
  }

  public async navigateToKunde(queryParams: Params = {}) {
    await this.router.navigate(this._kunde.url, { queryParams });
  }
  public async navigateToProjekt(queryParams: Params = {}) {
    await this.router.navigate(this._projekt.url, { queryParams });
  }
  public async navigateToTour(queryParams: Params = {}) {
    await this.router.navigate(this._tour.url, { queryParams });
  }
  public async navigateToDurchgang(queryParams: Params = {}) {
    await this.router.navigate(this._durchgang.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  /* << methods    */
  async openErinnerung(erinnerung: Erinnerung) {
    if (erinnerung.kunde) {
      await this.navigateToKunde({ id: erinnerung.kunde });
    } else if (erinnerung.projekt) {
      await this.navigateToProjekt({ id: erinnerung.projekt });
    }
  }

  async openTour(tour: ITour) {
    if (tour) {
      await this.navigateToTour({ id: tour.id });
    }
  }
  /*    methods >> */

  getMitarbeiterText(label: string) {
    if (!label) return '';
    return toInitials(label.split('(')[0]);
  }

  async abmelden(erinnerung: IErinnerung) {
    erinnerung.mitarbeiter = erinnerung.mitarbeiter?.filter((m: string) => m !== this.auth.$id.getValue());
    await this.postErinnerung.request({ erinnerung });
  }

  async onSelect(event: any, select = true) {
    const value = !select ? event.value : event;
    if (!value?.extra) return;

    const projekt: IProjektAnteil = value.extra.projekt;
    this.projektLabel = projekt.label;
    if (select) this.selected$.next(projekt.projekt);

    const anteil: any = {
      name: 'Projektanteile',
      series: [],
    };
    anteil.series.push({
      name: 'Besichtigung',
      value: projekt.ausBesichtigung,
    });
    anteil.series.push({
      name: 'Gutachten',
      value: projekt.ausGutachten,
    });
    anteil.series.push({
      name: 'Plausibilisierung',
      value: projekt.ausPrüfung,
    });

    const zeiten: any = {
      name: 'Bewertete Std.',
      series: [],
    };
    Object.entries(projekt.zeiten).forEach(([name, v]) => {
      zeiten.series.push({
        name,
        value: v,
        extra: { projekt: value.extra.projekt.projekt, start: value.extra.month.start, ende: value.extra.month.ende },
      });
    });

    const differenz: any = {
      name: 'Erfolg',
      series: [],
    };
    const guv = projekt.anteil - projekt.nachStunden;
    differenz.series.push({
      name: guv > 0 ? 'Gewinn' : 'Verlust',
      value: guv,
    });

    this.project$.next([anteil, zeiten, differenz]);

    this.monthLabel = value.extra.month.label;
    const month: IProjektAnteil[] = value.extra.month.rows;

    const monthAnteil: any = {
      name: 'Projektanteile',
      series: [],
    };
    monthAnteil.series.push({
      name: 'Besichtigung',
      value: month.reduce((n, { ausBesichtigung }) => n + (!ausBesichtigung || isNaN(ausBesichtigung) ? 0 : ausBesichtigung), 0),
    });
    monthAnteil.series.push({
      name: 'Gutachten',
      value: month.reduce((n, { ausGutachten }) => n + (!ausGutachten || isNaN(ausGutachten) ? 0 : ausGutachten), 0),
    });
    monthAnteil.series.push({
      name: 'Prüfung',
      value: month.reduce((n, { ausPrüfung }) => n + (!ausPrüfung || isNaN(ausPrüfung) ? 0 : ausPrüfung), 0),
    });

    const monthZeiten: any = {
      name: 'Bewertete Std.',
      series: [],
    };

    const monthZeitUnterarten: INachZeiten = {};
    month.forEach((projekt) => {
      Object.entries(projekt.zeiten).forEach(([unterart, value]) => {
        monthZeitUnterarten[unterart] = Number(monthZeitUnterarten[unterart]) || 0;
        monthZeitUnterarten[unterart] = Number(monthZeitUnterarten[unterart]) + Number(value);
      });
    });
    Object.entries(monthZeitUnterarten).forEach(([name, v]) => {
      monthZeiten.series.push({
        name,
        value: v,
        extra: { start: value.extra.month.start, ende: value.extra.month.ende, projekte: month.map(({ projekt }) => projekt) },
      });
    });

    const monthDifferenz: any = {
      name: 'Erfolg',
      series: [],
    };
    const guvMonth =
      month.reduce((n, { anteil }) => n + (!anteil || isNaN(anteil) ? 0 : anteil), 0) - month.reduce((n, { nachStunden }) => n + (!nachStunden || isNaN(nachStunden) ? 0 : nachStunden), 0);
    monthDifferenz.series.push({
      name: guvMonth > 0 ? 'Gewinn' : 'Verlust',
      value: guvMonth,
    });

    this.month$.next([monthAnteil, monthZeiten, monthDifferenz]);
  }

  onActivate($event: any) {
    if (!this.selected$.getValue()) this.onSelect($event, false);
  }

  async clickedZeiten($event: any) {
    if (!$event.extra) return;
    const jahr = moment($event.extra.start).year();
    const shapes: IZeitenQueryFilter = this.zeiten.filter$.getValue() || {};
    shapes['zeit.mitarbeiter'] = { operator: 'ist', value: [this.selectedMitarbeiter] };
    if ($event?.extra?.projekt) shapes['zeit.projekt'] = { operator: 'ist', value: [$event.extra.projekt] };
    else shapes['zeit.projekt'] = { operator: 'ist', value: $event.extra.projekte };
    this.zeiten.filter$.next(shapes);
    await this.zeiten.request({ ...(this.zeiten.payload$.getValue() || {}), jahr });
    await this.router.navigate(this._zeiten.url);
  }

  clear() {
    this.selected$.next('');
  }

  async selectMitarbeiter(mitarbeiter: string) {
    this.$loading.next(true);
    await this.sollIst.request({ mitarbeiter });
    this.$loading.next(false);
  }
}
