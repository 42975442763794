<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="projekte.search$.next($event)" [search]="projekte.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-projekte-filter [style.width]="'100%'"></fa-kt-projekte-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-projekte-sorter [style.width]="'100%'"></fa-kt-projekte-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-projekte-list [style.width]="'100%'" (select)="setProjekte($event)" [loading]="loading$ | async"></fa-kt-projekte-list>



  <fa-kt-import [style.width]="'100%'" ></fa-kt-import>


    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="projekt" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="archiv" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="umkreissuche" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <span *nzSpaceItem style="line-height: 32px">
        {{ ((filter.mine ? ((projekte.result$ | async)?.projekte | mine:(auth.$id | async)) : filter.unassigned ? ((projekte.result$ | async)?.projekte | unassigned) : (filter.erinnerungen ?
        ((projekte.result$ | async)?.projekte | erinnerungen) : (projekte.result$ | async)?.projekte)))?.length }} / {{ (projekte.response$ | async)?.projekte?.length }}
      </span>
      <ng-container *ngIf="!filter.map">
        <label *nzSpaceItem nz-checkbox [(ngModel)]="filter.mine" (ngModelChange)="filter.setMine($event)">
          <i nz-icon nzType="user" nzTheme="outline"></i>
          Meine
        </label>
      </ng-container>
      <ng-container *ngIf="!filter.map">
        <label *nzSpaceItem nz-checkbox [(ngModel)]="filter.unassigned" (ngModelChange)="filter.setUnassigned($event)">
          <i nz-icon nzType="file-unknown" nzTheme="outline"></i>
          Unzugeordnete
        </label>
      </ng-container>
      <label *nzSpaceItem nz-checkbox [(ngModel)]="filter.map">
        <i nz-icon nzType="pushpin" nzTheme="outline"></i>
        Karte
      </label>
      <label *nzSpaceItem nz-checkbox [(ngModel)]="filter.archiv" (ngModelChange)="refresh()">
        <i nz-icon nzType="inbox" nzTheme="outline"></i>
        Archiv
      </label>
      <button nz-button *nzSpaceItem (click)="refresh()" nzType="dashed">
        <i nz-icon nzType="reload" nzTheme="outline"></i>
        Neu laden
      </button>
      <div *nzSpaceItem style="width: 100%">
        <fa-kt-saved-projekte-filters style="width: 100%"></fa-kt-saved-projekte-filters>
      </div>
      <button *nzSpaceItem nz-button nzType="primary" (click)="setProjekte('new')">
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        Projekt hinzufügen
      </button>
      <button *nzSpaceItem nz-button nz-dropdown [nzDropdownMenu]="more" nzPlacement="bottomRight" nzType="default">
        <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
      </button>
      <nz-dropdown-menu #more="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="exportAuswahl()">
            <i nz-icon nzType="file-excel" nzTheme="outline" style="padding-right: 6px"></i>
            Auswahl exportieren
          </li>
          <li nz-menu-item (click)="startUpload()">
            <i nz-icon nzType="cloud-upload" nzTheme="outline" style="padding-right: 6px"></i>
            Importieren
          </li>
          <li nz-menu-item (click)="navigateToUmkreissuche()">{{ _umkreissuche.emoji }} Zur {{ _umkreissuche.title }}</li>
        </ul>
      </nz-dropdown-menu>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <pbc-search-bar [style.width]="'100%'" (searchChange)="projekte.search$.next($event)" [search]="projekte.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
      <fa-kt-projekte-filter [style.width]="'100%'"></fa-kt-projekte-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
      <fa-kt-projekte-sorter [style.width]="'100%'"></fa-kt-projekte-sorter>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col nzSpan="24">
      <nz-spin [nzSpinning]="loading$ | async">
        <nz-row *ngIf="filter.map">
          <nz-col [nzSpan]="popup ? 16 : 24">
            <pbc-atlas-map
              height="calc(100vh - 332px)"
              width="100%"
              [resize]="$resize | async"
              [coordinateArray]="$coordinateArrayWithSelected | async"
              mode="addresses"
              (clicked)="showProjekt($event)"
            ></pbc-atlas-map>
          </nz-col>
          <nz-col *ngIf="popup" nzSpan="8" style="padding: 24px">
            <nz-space style="float: right">
              <a *nzSpaceItem nz-button nzType="link" [routerLink]="_projekt.url" [queryParams]="{ id: popup.projekt.id }">Öffnen <span nz-icon nzType="arrow-right" nzTheme="outline"></span></a>
              <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="showProjekt()">
                <span nz-icon nzType="close"></span>
              </button>
            </nz-space>
            <br />
            <h4>{{popup.projekt.nummer}} | {{popup.projekt.bezeichnung}}</h4>
            <h2>{{popup.objekt.name}}</h2>
            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.projekt.kunde | type: (projekte.projektKunde | async)" [nzTitle]="'Kunde'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.gutachten.objektNutzung | type: (projekte.gutachtenObjektNutzung | async)" [nzTitle]="'Nutzung'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.gutachten.objektArt | type: (projekte.gutachtenObjektArt | async)" [nzTitle]="'Nutzung'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.gutachten.baujahr || '-'" [nzTitle]="'Baujahr'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.marktwert | number:'0.2-2':'de') || '-'" [nzTitle]="'Marktwert'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.stichtagMarktwert | date:'dd.MM.yyyy') || '-'" [nzTitle]="'Stichtag'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.flaeche | number:'0.2-2':'de') || '-'" [nzTitle]="'Flächen'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.jahresrohertrag | number:'0.2-2':'de') || '-'" [nzTitle]="'JRoE'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.vervielfaeltiger | number:'0.0-3':'de') || '-'" [nzTitle]="'Rohertragsvervielfältiger'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.gebaeudefaktor | number:'0.2-2':'de') || '-'" [nzTitle]="'Marktwert pro m²'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.gutachten.erbbaurecht ? '✅' : '⛔️'" [nzTitle]="'Erbbaurecht'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.projekt.aktiv ? '✅' : '⛔️'" [nzTitle]="'Aktiv'"></nz-statistic>
              </nz-col>
            </nz-row>
          </nz-col>
        </nz-row>
        <div style="overflow: auto" *ngIf="!filter.map">
          <div style="min-width: 2100px">
            <nz-row>
              <nz-col nzSpan="24">
                <span>
                  <span nz-typography nzType="secondary" class="text-lg" style="width: 160px">Nr.</span>

                  <span nz-typography nzType="secondary" class="text-xl">Bezeichnung</span>

                  <span nz-typography nzType="secondary" class="text-md">Beauftragung</span>

                  <span nz-typography nzType="secondary" class="text-md">Deadline</span>

                  <span nz-typography nzType="secondary" style="width: 60px"> Besicht. </span>

                  <span nz-typography nzType="secondary" style="width: 60px"> Gutach. </span>

                  <span nz-typography nzType="secondary" style="width: 30px"> Plau. </span>

                  <span nz-typography nzType="secondary" style="width: 60px"> Partn. </span>

                  <span nz-typography nzType="secondary" class="text-lg" style="width: 180px !important"> Projektstatus </span>

                  <span nz-typography nzType="secondary" class="text-lg"> Bewertungsanlass </span>

                  <span nz-typography nzType="secondary" class="text-md">Objekt</span>

                  <span nz-typography nzType="secondary" class="text-md">Art</span>

                  <span nz-typography nzType="secondary" class="text-lg">Gutachtenstatus</span>

                  <pbc-color-icon>Fortschritt</pbc-color-icon>

                  <span nz-typography nzType="secondary" class="text-md">Besichtigung</span>

                  <span nz-typography nzType="secondary" class="text-md">BS</span>
                  <span nz-typography nzType="secondary" class="text-md">RA</span>
                  <pbc-color-icon>RE</pbc-color-icon>
                  <span nz-typography nzType="secondary" class="text-md">Vermerke</span>

                  <span nz-typography nzType="secondary" class="text-lg">Zeiten</span>

                  <!-- <span nz-typography nzType="secondary" class="text-md">Kommentar</span> -->
                </span>
              </nz-col>
            </nz-row>
            <cdk-virtual-scroll-viewport *ngIf="projekte.result$ | async" [itemSize]="itemSize" [style.height]="'calc(100vh - 332px)'" cdkScrollable>
              <!-- <div [style.height]="filter.map ? 'calc(100vh - 656px)' : 'calc(100vh - 332px)'"> -->
              <nz-row nzSize="large">
                <nz-col
                  nzSpan="24"
                  *cdkVirtualFor="let entry of filter.mine ? ((result$ | async) | mine:(auth.$id | async)) : filter.unassigned ? ((result$ | async) | unassigned) : filter.erinnerungen ? (result$ | async | erinnerungen) : (result$ | async); let i = index"
                  style="display: block"
                  [style.backgroundColor]="i % 2 === 0 ? 'gainsboro' : ''"
                >
                  <a [routerLink]="_projekt.url" [queryParams]="{ id: entry.projekt.id }">
                    <span nz-typography class="text-lg" style="width: 160px">{{ entry.projekt.nummer }} </span>

                    <span nz-typography class="text-xl">{{ entry.projekt.bezeichnung }} </span>

                    <span nz-typography class="text-md" nz-tooltip nzTooltipTitle="Beauftragung">{{ entry.projekt.beauftragung ? (entry.projekt.beauftragung | date:'dd.MM.yyyy') : '?' }} </span>

                    <span nz-typography class="text-md" nz-tooltip nzTooltipTitle="Deadline">{{ entry.projekt.deadline ? (entry.projekt.deadline | date:'dd.MM.yyyy') : '?' }} </span>

                    <nz-avatar-group nzSize="small" style="width: 60px; margin-top: 4px" (click)="editProjekt(entry.projekt, $event)">
                      <ng-container [ngSwitch]="entry.projekt.keineBesichtigungErforderlich === true">
                        <ng-container *ngSwitchCase="false">
                          <nz-avatar
                            *ngFor="let mitarbeiter of entry.projekt?.besichtigungen"
                            [nzSize]="'small'"
                            [nzSrc]="mitarbeiter | type:(meta.users$ | async):'avatar'"
                            [nzText]="mitarbeiter | type:(meta.users$ | async):'initialen'"
                            nz-tooltip
                            [nzTooltipTitle]="'Besichtigung: ' + (mitarbeiter | type:(meta.users$ | async):'label')"
                          ></nz-avatar>
                          <nz-avatar
                            *ngIf="!entry.projekt?.besichtigungen || entry.projekt?.besichtigungen?.length === 0"
                            [nzSize]="16"
                            nzIcon="edit"
                            nz-tooltip
                            nzTooltipTitle="Projekt bearbeiten"
                          ></nz-avatar>
                        </ng-container>
                        <nz-avatar *ngSwitchDefault [nzSize]="'small'" nzIcon="close" nz-tooltip [nzTooltipTitle]="'Keine Besichtigung'"></nz-avatar>
                      </ng-container>
                    </nz-avatar-group>
                    <nz-avatar-group nzSize="small" style="width: 60px; margin-top: 4px" (click)="editProjekt(entry.projekt, $event)">
                      <nz-avatar
                        *ngFor="let mitarbeiter of entry.projekt?.gutachten"
                        [nzSrc]="mitarbeiter | type:(meta.users$ | async):'avatar'"
                        nzSize="small"
                        [nzText]="mitarbeiter | type:(meta.users$ | async):'initialen'"
                        nz-tooltip
                        [nzTooltipTitle]="'Gutachten: ' + (mitarbeiter | type:(meta.users$ | async):'label')"
                      ></nz-avatar>
                      <nz-avatar *ngIf="!entry.projekt?.gutachten || entry.projekt?.gutachten?.length === 0" [nzSize]="16" nzIcon="edit" nz-tooltip nzTooltipTitle="Projekt bearbeiten"></nz-avatar>
                    </nz-avatar-group>
                    <nz-avatar-group nzSize="small" style="width: 30px; margin-top: 4px" (click)="editProjekt(entry.projekt, $event)">
                      <nz-avatar
                        *ngIf="entry.projekt.pruefung"
                        [nzSize]="'small'"
                        [nzSrc]="entry.projekt.pruefung | type:(meta.users$ | async):'avatar'"
                        [nzText]="entry.projekt.pruefung | type:(meta.users$ | async):'initialen'"
                        nz-tooltip
                        [nzTooltipTitle]="'Plausibilisierung: ' + (entry.projekt.pruefung | type:(meta.users$ | async):'label')"
                      ></nz-avatar>
                      <nz-avatar *ngIf="!entry.projekt?.pruefung" [nzSize]="16" nzIcon="edit" nz-tooltip nzTooltipTitle="Projekt bearbeiten"></nz-avatar>
                    </nz-avatar-group>
                    <nz-avatar-group nzSize="small" style="width: 60px; margin-top: 4px" (click)="editProjekt(entry.projekt, $event)">
                      <nz-avatar
                        *ngFor="let mitarbeiter of entry.projekt?.partnerInnen"
                        nzSize="small"
                        [nzSrc]="mitarbeiter | type:(meta.users$ | async):'avatar'"
                        [nzText]="mitarbeiter | type:(meta.users$ | async):'initialen'"
                        nz-tooltip
                        [nzTooltipTitle]="'Partner*in: ' + (mitarbeiter | type:(meta.users$ | async):'label')"
                      ></nz-avatar>
                      <nz-avatar
                        *ngIf="!entry.projekt?.partnerInnen || entry.projekt?.partnerInnen?.length === 0"
                        [nzSize]="16"
                        nzIcon="edit"
                        nz-tooltip
                        nzTooltipTitle="Projekt bearbeiten"
                      ></nz-avatar>
                    </nz-avatar-group>

                    <span class="text-lg" style="width: 180px !important" [style.color]="entry.projekt.projektStatus | type:(projekte.projektProjektStatus | async):'color'">
                      <i nz-icon [nzType]="entry.projekt.projektStatus | type:(projekte.projektProjektStatus | async):'icon'" nzTheme="outline"></i>
                      {{ entry.projekt.projektStatus | type:(projekte.projektProjektStatus | async) }}
                    </span>

                    <span class="text-lg" nz-typography nzEllipsis> {{ entry.projekt.bewertungsAnlass | type:(projekte.gutachtenBewertungsAnlass | async) }} </span>

                    <span nz-typography class="text-md">{{ entry.gutachten[0]?.objektArt | type:(projekte.gutachtenObjektArt | async) }}</span>

                    <span nz-typography class="text-md">{{ entry.projekt.projektArt | type:(projekte.projektProjektArt | async) }}</span>

                    <ng-container [ngSwitch]="entry.gutachten.length < 2">
                      <ng-container *ngSwitchCase="true" [ngSwitch]="entry.gutachten.length === 0">
                        <ng-container *ngSwitchCase="true">
                          <span nz-typography class="text-lg">0 Gutachten</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="false">
                          <span nz-typography class="text-lg">
                            <span [style.color]="entry.gutachten[0].bewertungsStatus | type:(projekte.gutachtenBewertungsStatus | async):'color'">
                              <i nz-icon [nzType]="entry.gutachten[0].bewertungsStatus | type:(projekte.gutachtenBewertungsStatus | async):'icon'" nzTheme="outline"></i>
                              {{ entry.gutachten[0].bewertungsStatus | type:(projekte.gutachtenBewertungsStatus | async) }}
                            </span>
                          </span>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngSwitchCase="false">
                        <span nz-typography class="text-lg" nz-dropdown [nzDropdownMenu]="gutachtenDropdown">{{ entry.gutachten.length }} Gutachten</span>
                        <nz-dropdown-menu #gutachtenDropdown="nzDropdownMenu">
                          <ul nz-menu nzSelectable>
                            <li
                              nz-menu-item
                              *ngFor="let row of entry.entries"
                              (click)="navigateToProjekt({ id: entry.projekt.id, tab: 'gutachten', gutachten: row.gutachten.id, detail: 'gutachten' })"
                            >
                              <pbc-color-icon [color]="getColorByFortschritt(row.gutachten.fortschritt)"></pbc-color-icon>
                              <span nz-typography> {{ row.objekt?.addresse?.strasse }} {{ row.objekt?.addresse?.plz }} {{ row.objekt?.addresse.land }} </span>
                              <nz-divider nzType="vertical"></nz-divider>
                              <span [style.color]="row.gutachten.bewertungsStatus | type:(projekte.gutachtenBewertungsStatus | async):'color'">
                                <i nz-icon [nzType]="row.gutachten.bewertungsStatus | type:(projekte.gutachtenBewertungsStatus | async):'icon'" nzTheme="outline"></i>
                                {{ row.gutachten.bewertungsStatus | type:(projekte.gutachtenBewertungsStatus | async) }}
                              </span>
                            </li>
                          </ul>
                        </nz-dropdown-menu>
                      </ng-container>
                    </ng-container>

                    <pbc-color-icon [color]="getColorByFortschritt(entry.projekt.fortschritt)" nz-tooltip [nzTooltipTitle]="Fortschritt"></pbc-color-icon>
                    <ng-template #Fortschritt>
                      <span> {{ (entry.projekt.fortschritt || 0) | number:'0.0-2' }} % Fortschritt </span>
                    </ng-template>

                    <span nz-typography class="text-md" nz-tooltip nzTooltipTitle="Besichtigung">
                      {{ entry.besichtigungen[0]?.von ? (entry.besichtigungen[0]?.von | date: 'dd.MM.yyyy') : entry.gutachten[0]?.datum_besichtigung ? (entry.gutachten[0]?.datum_besichtigung | date:
                      'dd.MM.yyyy') : ''}}</span
                    >

                    <span nz-typography nz-tooltip nzTooltipTitle="Bewertete Stunden" class="text-md" [style.color]="getProjektKostenColor(entry.projekt.kostenNachStunden)"
                      >{{ showKosten(entry) ? ((entry.projekt.kostenNachStunden || 0) | currency:'EUR':'':'':'de') : ''}}</span
                    >
                    <span nz-typography nz-tooltip nzTooltipTitle="Rechnungsausgang" class="text-md" [style.color]="getProjektKostenColor(entry.projekt.kosten)"
                      >{{ showKosten(entry) ? ((entry.projekt.kostenNetto || 0) | currency:'EUR':'':'':'de') : '' }}</span
                    >
                    <pbc-color-icon nz-tooltip nzTooltipTitle="Rechnungseingang" [color]="getProjektKostenBeglichenColor(entry.projekt.kosten, entry.projekt.kostenBeglichen)"></pbc-color-icon>
                    <ng-container *ngIf="!entry.projekt.aktiv">
                      <i nz-icon nzType="inbox" nzTheme="outline"></i>
                      <span nz-typography class="text-md"> ARCHIVIERT</span>
                    </ng-container>
                    <ng-container *ngIf="entry.projekt.aktiv">
                      <span nz-typography class="text-md">
                        <ng-container *ngIf="entry.vermerke">
                          <ng-container *ngIf="entry.vermerke.length === 0">
                            <span nz-typography nzEllipsis nz-typography nzType="secondary">{{ entry.vermerke?.length }} Vermerk{{ entry.vermerke?.length === 1 ? '' : 'e' }}</span>
                          </ng-container>
                          <ng-container *ngIf="entry.vermerke.length > 0">
                            <span nz-typography nzEllipsis nz-dropdown [nzDropdownMenu]="vermerkeDropdown">{{ entry.vermerke[0].kommentar }}</span>
                          </ng-container>
                        </ng-container>
                      </span>
                      <nz-dropdown-menu #vermerkeDropdown="nzDropdownMenu">
                        <ul nz-menu nzSelectable *ngIf="entry.vermerke">
                          <cdk-virtual-scroll-viewport [itemSize]="98" style="height: calc(98px * 3); width: 666px">
                            <div *cdkVirtualFor="let vermerk of entry.vermerke" [routerLink]="_projekt.url" [queryParams]="{ id: entry.projekt.id }">
                              <nz-comment [nzAuthor]="vermerk.verfasser | type:(meta.users$ | async)" [nzDatetime]="vermerk._createdAt | date:'dd.MM.yyyy'">
                                <nz-avatar
                                  nz-comment-avatar
                                  [nzSrc]="vermerk.verfasser | type:(meta.users$ | async):'avatar'"
                                  [nzText]="vermerk.verfasser | type:(meta.users$ | async):'initialen'"
                                  nz-tooltip
                                  [nzTooltipTitle]="vermerk.verfasser | type:(meta.users$ | async)"
                                ></nz-avatar>
                                <nz-comment-content>
                                  <p>{{ vermerk.kommentar }}</p>
                                </nz-comment-content>
                              </nz-comment>
                            </div>
                          </cdk-virtual-scroll-viewport>
                        </ul>
                      </nz-dropdown-menu>
                    </ng-container>

                    <ng-container *ngIf="entry.projekt.aktiv" [ngSwitch]="entry.zeiten?.length > 0">
                      <span *ngSwitchCase="true" class="text-lg" nz-typography nzEllipsis nz-dropdown [nzDropdownMenu]="zeitenDropdown">{{ entry.zeit }} </span>
                      <span *ngSwitchCase="false" class="text-lg" nz-typography nzEllipsis>{{ entry.zeit }} </span>
                      <nz-dropdown-menu #zeitenDropdown="nzDropdownMenu">
                        <ul nz-menu nzSelectable *ngIf="entry.zeiten">
                          <cdk-virtual-scroll-viewport [itemSize]="98" style="height: calc(98px * 3); width: 666px">
                            <div *cdkVirtualFor="let zeit of entry.zeiten">
                              <nz-comment [nzAuthor]="zeit.mitarbeiter | type:(meta.users$ | async)" [nzDatetime]="zeit._createdAt | date:'dd.MM.yyyy'" (click)="openZeiten(entry.projekt.id, zeit.id)">
                                <nz-avatar
                                  nz-comment-avatar
                                  [nzSrc]="zeit.verfasser | type:(meta.users$ | async):'avatar'"
                                  [nzText]="zeit.verfasser | type:(meta.users$ | async):'initialen'"
                                  nz-tooltip
                                  [nzTooltipTitle]="zeit.mitarbeiter | type:(meta.users$ | async)"
                                ></nz-avatar>
                                <nz-comment-content>
                                  <p>
                                    <strong>{{ zeit.zeitArt | type:($zeitArts | async) }} - {{ zeit.zeitUnterart | type:($zeitUnterarts | async) }}</strong><br />
                                    {{ zeit.stunden || 0 }} Std. {{ zeit.minuten || 0 }} Min.<br />
                                    {{ zeit.kommentar }}
                                  </p>
                                </nz-comment-content>
                              </nz-comment>
                            </div>
                          </cdk-virtual-scroll-viewport>
                        </ul>
                      </nz-dropdown-menu>
                    </ng-container>

                    <!-- <span *ngIf="showKommentar(entry.projekt)" class="text-md" nz-tooltip [nzTooltipTitle]="entry.projekt.kommentar" nz-typography nzEllipsis>{{entry.projekt.kommentar}}</span> -->
                  </a>
                </nz-col>
              </nz-row>
              <!-- </div> -->
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </nz-spin>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>

<nz-modal (nzOnCancel)="editProjekt()" [nzTitle]="ModalTitle" [nzVisible]="!!($projekt | async)" [nzWidth]="1200">
  <ng-template #ModalTitle>
    <nz-row>
      <nz-col nzSpan="16">
        <h4 nz-title>{{ ($projekt | async)?.nummer }} | {{ ($projekt | async)?.bezeichnung }}</h4>
      </nz-col>
      <nz-col nzSpan="8">
        <nz-space>
          <button *nzSpaceItem nz-button nzType="default" [routerLink]="_projekt.url" [queryParams]="{ id: ($projekt | async)?.id }">
            <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
            Öffnen
          </button>
          <fa-kt-delete-projekt *nzSpaceItem [style.width]="'100%'" [value]="{ id: ($projekt | async)?.id}" (finished)="editProjekt()"></fa-kt-delete-projekt>
        </nz-space>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-container *nzModalContent>
    <fa-kt-post-projekt [style.width]="'100%'" [value]="{ projekt: $projekt | async}" (finished)="editProjekt()"></fa-kt-post-projekt>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="editProjekt()">Abbrechen</button>
  </div>
</nz-modal>

<nz-button-group style="position: absolute; right: 24px; bottom: 6px" *ngIf="!filter.map">
  <button nz-button nzType="primary" (click)="scrollDown()">
    <span nz-icon nzType="down" nzTheme="outline"></span>
  </button>
  <button nz-button nzType="primary" (click)="scrollUp()">
    <span nz-icon nzType="up" nzTheme="outline"></span>
  </button>
</nz-button-group>
