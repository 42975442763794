import { DestroyRef, Inject, inject, Injectable } from '@angular/core';

import isEqual from 'lodash/isEqual';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActionService, AppEnvironment, APP_CONFIG } from '../../../common';
import { HttpService } from '../../../https';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly TOKEN_KEY = 'base';
  readonly AUTHORIZED_ROUTES_KEY = 'authorizedRoutes';

  readonly baseURL: string;
  readonly authRequest;

  $loading = new BehaviorSubject<boolean>(false);
  $accounts: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  $token: BehaviorSubject<any> = new BehaviorSubject(null);

  $id: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  $displayName: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  $email: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  $avatar: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  $isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $isOM: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $isPartner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $isUrlaubsAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $isFinanzen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $groups: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  $routes = new BehaviorSubject<Array<string>>([]);

  $visitedRoute = new BehaviorSubject<string | undefined>(undefined);

  $ADgroups: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  $ADmemberships: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

  actions = inject(ActionService);
  environment = inject(APP_CONFIG) as AppEnvironment;
  destroyedRef = inject(DestroyRef);
  messages = inject(NzMessageService);
  notifications = inject(NzMessageService);
  http = inject(HttpService);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msal: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {
    this.baseURL = this.environment.auth.baseURL;
    this.authRequest = {
      scopes: this.environment.auth.scopes,
    };
    this.$id.pipe(distinctUntilChanged()).subscribe(async (id) => {
      if (id) {
        this.actions.unsubscribe('Anmelden');
        this.actions.subscribe({ key: 'Abmelden', action: () => this.logout() });
        await this.refreshGroups(id);
      } else {
        this.actions.unsubscribe('Abmelden');
        this.actions.subscribe({ key: 'Anmelden', action: () => this.login() });
      }
    });
    combineLatest([this.$ADgroups.pipe(distinctUntilChanged((a, b) => a && b && isEqual(a, b))), this.$ADmemberships]).subscribe(([groups, memberships]) => {
      if (!groups || !memberships || groups.some((group) => group.isMember)) {
        return;
      }
      const ADgroups = groups.map((group) => ({ ...group, isMember: memberships.map((m) => m.id).indexOf(group.id) > -1 }));
      this.$ADgroups.next(ADgroups);
    });
  }

  async init() {
    this.msal.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED))
      .subscribe((result: EventMessage) => {
        if (this.msal.instance.getAllAccounts().length === 0) window.location.pathname = '/';
        else this.$accounts.next(this.msal.instance.getAllAccounts());
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(this.destroyedRef),
      )
      .subscribe(async () => {
        let activeAccount = this.msal.instance.getActiveAccount();
        if (activeAccount) await this.setActiveAccount(activeAccount);
        if (!activeAccount && this.msal.instance.getAllAccounts().length > 0) {
          let accounts = this.msal.instance.getAllAccounts();
          this.msal.instance.setActiveAccount(accounts[0]);
        }
      });

    // this.msal.handleRedirectObservable().subscribe({
    //   next: async (result: AuthenticationResult) => {
    //     if (result) await this.refreshToken(result);
    //   },
    //   error: (error) => console.error(error),
    // });
    // this.msal.instance.addEventCallback(async (event: any) => {
    //   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    //     await this.setActiveAccount(event.payload.account);
    //   }
    // });
  }

  async login() {
    try {
      await this.msal.loginRedirect({ ...this.msalGuardConfig.authRequest, ...this.authRequest } as RedirectRequest).toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  private async setActiveAccount(account: any) {
    this.msal.instance.setActiveAccount(account);
    await this.refreshToken();
  }

  private async refreshToken(token?: AuthenticationResult) {
    await this.msal.initialize();
    try {
      token = await this.msal.acquireTokenSilent(this.authRequest).toPromise();
      await this.setAuthentication(token);
      return token;
    } catch (error) {
      await this.msal.acquireTokenRedirect({ ...this.authRequest }).toPromise();
      return undefined;
    }
  }

  private async setAuthentication(token?: AuthenticationResult) {
    if (!token) {
      return;
    }
    console.debug(token);
    this.$loading.next(true);
    this.http.$Authorization.next(token.idToken);
    this.$token.next(token.accessToken);
    // token.uniqueId = 'be7abc5b-5868-4a10-ac17-57d919af21dd'; // '07bb63c8-3023-4469-84f7-85379f6e8968';
    this.$id.next(token.uniqueId);
    // localStorage.setItem(this.TOKEN_KEY, JSON.stringify(token));
    this.$loading.next(false);
  }

  logout() {
    const account = this.msal.instance.getActiveAccount();
    // localStorage.setItem(this.AUTHORIZED_ROUTES_KEY, JSON.stringify([]));
    // localStorage.setItem(this.TOKEN_KEY, JSON.stringify(''));
    this.msal.logoutRedirect({ account });
  }

  access(target: string, routes?: string[], strict = false): boolean {
    routes = routes || this.$routes.getValue();
    target = target.split('//').join('/').split('//').join('/');
    if (target.indexOf('?') >= 0) target = target.substring(0, target.indexOf('?'));
    return routes.some((route) => (strict ? route.endsWith(target) : route.includes(target)));
  }

  get headers() {
    return { Authorization: 'Bearer ' + this.$token.getValue() };
  }

  async updatePassword(id: string) {
    try {
      await this.http.patch(this.baseURL + `users/${id}`, {
        passwordProfile: { forceChangePasswordNextSignIn: true },
      });
      this.messages.success('Bei der nächsten Anmeldung wird ein neues Passwort verlangt');
      if (id === this.$id.getValue()) {
        this.logout();
      }
    } catch (error: any) {
      console.error(error);
      this.notifications.error(`something has gone wrong here: ${error.error.error.message}`);
    }
  }

  async refreshGroups(id: string) {
    let [groups, memberships] = await Promise.all([this.http.get<any[]>(this.baseURL + 'groups'), this.refreshMemberships(id)]);
    groups = groups ? groups : [];
    memberships = memberships ? memberships : [];
    groups = groups.filter((group: any) => group).map((group) => ({ ...group, emailOrSecurity: !group.groupTypes || group.groupTypes.length === 0 }));
    const ADgroups = groups.map((group: any) => ({ ...group, isMember: memberships.map((m: any) => m.id).indexOf(group.id) > -1 }));
    this.$ADgroups.next(ADgroups);
  }

  async refreshMemberships(id: string) {
    if (!id) {
      this.$ADmemberships.next([]);
    }
    const memberships: any = await this.http.get(this.baseURL + `users/${id}/memberOf`);
    this.$ADmemberships.next(memberships);
    return memberships;
  }

  async addMemberToGroup(group: string, id: string) {
    try {
      await this.http.post(this.baseURL + `groups/${group}/members/$ref`, { '@odata.id': this.baseURL + `directoryObjects/${id}` });
      await this.refreshMemberships(id);
      this.messages.success('Mitgliedschaft hinzugefügt.');
    } catch (error: any) {
      await this.refreshGroups(id);
      console.error(error);
      this.notifications.error(`something has gone wrong here: ${error.error.error.message}`);
    }
  }

  async removeMemberFromGroup(group: string, id: string) {
    try {
      await this.http.delete(this.baseURL + `groups/${group}/members/${id}/$ref`);
      await this.refreshMemberships(id);
      this.messages.success('Mitgliedschaft entfernt.');
    } catch (error: any) {
      await this.refreshGroups(id);
      console.error(error);
      this.notifications.error(`something has gone wrong here: ${error.error.error.message}`);
    }
  }
}
