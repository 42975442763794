<nz-upload
  nzType="drag"
  [nzMultiple]="multi"
  style="width: 100%; height: calc(100% + 48px)"
  [(nzFileList)]="fileList"
  (nzChange)="handleChange($event)"
  [nzShowUploadList]="true"
  [nzBeforeUpload]="beforeUpload()"
>
  <p class="ant-upload-drag-icon">
    <i nz-icon [nzType]="icon"></i>
  </p>
  <p class="ant-upload-text">
    {{ text }}
  </p>
  <p class="ant-upload-hint">
    {{ hint }}
  </p>
</nz-upload>
