import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import clone from 'lodash/clone';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapContext, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IPostProjekteHochzaehlenRequest, IPostProjekteHochzaehlenResponse, IPostRechnungenHochzaehlenRequest, IPostRechnungenHochzaehlenResponse } from 'fa-kt.types';
import { IEinstellungenPageResolverResult } from '.';
import { PostProjekteHochzaehlenCommandService, PostRechnungenHochzaehlenCommandService } from '../../..';

@Component({
  selector: 'fa-kt-einstellungen-page',
  templateUrl: './einstellungen.page.html',
  styleUrls: ['./einstellungen.page.css'],
})
export class EinstellungenPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postProjekteHochzaehlen: BehaviorSubject<IPostProjekteHochzaehlenRequest | null> = new BehaviorSubject<IPostProjekteHochzaehlenRequest | null>(null);
  public readonly $postRechnungenHochzaehlen: BehaviorSubject<IPostRechnungenHochzaehlenRequest | null> = new BehaviorSubject<IPostRechnungenHochzaehlenRequest | null>(null);

  readonly search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  $settings = new BehaviorSubject<{ context: ISitemapContext; pages: ISitemapPage[] }[]>([]);
  filtered$ = new BehaviorSubject<{ context: ISitemapContext; pages: ISitemapPage[] }[]>([]);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public postProjekteHochzaehlen: PostProjekteHochzaehlenCommandService,
    public postRechnungenHochzaehlen: PostRechnungenHochzaehlenCommandService,
  ) {
    this.page = this.sitemap.EINSTELLUNGEN.Pages.EINSTELLUNGEN;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IEinstellungenPageResolverResult = data.querys;
          /* << resolver    */
          /*    resolver >> */
        }),
        /* << subscriptions    */
        combineLatest([this.$settings, this.search$]).subscribe(([settings, search]) => {
          if (!search || search.length === 0) {
            this.filtered$.next(settings);
          } else {
            search = search.toLowerCase();
            this.filtered$.next(
              settings
                .slice()
                .map((panel) => clone(panel))
                .filter((panel) => {
                  if ((panel.context.title + panel.context.emoji + panel.context.subtitle).toLowerCase().indexOf(search) < 0) {
                    panel.pages = panel.pages.filter((page) => (page.title + page.emoji + page.subtitle + page.url.toString()).toLowerCase().indexOf(search) >= 0);
                  }
                  return panel;
                }),
            );
          }
        }),
        this.$settings.subscribe((settings) => {
          settings
            .slice()
            .reverse()
            .forEach((section) => {
              section.pages
                .slice()
                .reverse()
                .forEach((page) => {
                  this.actions.subscribe({
                    key: 'Zu ' + section.context.title + ' → ' + page.title,
                    action: () => this.router.navigate(page.url).catch(),
                  });
                });
            });
        }),
        combineLatest([this.auth.$routes, this.auth.$isAdmin]).subscribe(([routes, admin]) => {
          this.$settings.getValue().forEach((section) => {
            section.pages.forEach((page) => {
              this.actions.unsubscribe('Zu ' + section.context.title + ' → ' + page.title);
            });
          });

          const settings = [];

          const PERSONEN = {
            context: this.sitemap.PERSONEN,
            pages: [this.sitemap.PERSONEN.Pages.MITARBEITER, this.sitemap.PERSONEN.Settings.ROLLES, this.sitemap.PERSONEN.Settings.STANDORTS].filter(
              (page) => page && (admin || !!this.auth.access(page.url.join('/'), routes, true)),
            ),
          };
          if (PERSONEN.pages.length > 0) {
            settings.push(PERSONEN);
          }

          const PROJEKTE = {
            context: this.sitemap.PROJEKTE,
            pages: [
              this.sitemap.KUNDEN.Settings.KUNDEN_GRUPPES,
              this.sitemap.KUNDEN.Settings.KUNDEN_ARTS,
              this.sitemap.PROJEKTE.Settings.PROJEKT_ARTS,
              this.sitemap.PROJEKTE.Settings.PROJEKT_STATI,
              this.sitemap.GUTACHTEN.Settings.BEWERTUNGS_ANLASSS,
              this.sitemap.DOKUMENTE.Settings.DOKUMENTEN_ARTS,
            ].filter((page) => page && (admin || !!this.auth.access(page.url.join('/'), routes, true))),
          };
          if (PROJEKTE.pages.length > 0) {
            settings.push(PROJEKTE);
          }

          const BEWERTUNGEN = {
            context: this.sitemap.GUTACHTEN,
            pages: [
              this.sitemap.OBJEKTE.Settings.OBJEKT_NUTZUNGS,
              this.sitemap.OBJEKTE.Settings.OBJEKT_ARTS,
              this.sitemap.GUTACHTEN.Settings.GUTACHTEN_STATI,
              this.sitemap.BESICHTIGUNGEN.Settings.BESICHTIGUNGS_STATI,
              this.sitemap.BESICHTIGUNGEN.Settings.TOUR_STATI,
              this.sitemap.DOKUMENTE.Settings.ERFORDERLICHES_DOKUMENTS,
              this.sitemap.FINANZEN.Settings.REISEKOSTENABRECHNUNG_STATI,
            ].filter((page) => page && (admin || !!this.auth.access(page.url.join('/'), routes, true))),
          };
          if (BEWERTUNGEN.pages.length > 0) {
            settings.push(BEWERTUNGEN);
          }

          const FELDER = {
            context: this.sitemap.FELDER,
            pages: [this.sitemap.FELDER.Settings.FELD_KATEGORIES, this.sitemap.FELDER.Settings.FELD_UNTERKATEGORIES, this.sitemap.FELDER.Pages.FELDER].filter(
              (page) => page && (admin || !!this.auth.access(page.url.join('/'), routes, true)),
            ),
          };
          if (FELDER.pages.length > 0) {
            settings.push(FELDER);
          }

          const MARKT = {
            context: this.sitemap.MARKT,
            pages: [this.sitemap.MARKT.Settings.HERAUSGEBERS].filter((page) => page && (admin || !!this.auth.access(page.url.join('/'), routes, true))),
          };
          if (MARKT.pages.length > 0) {
            settings.push(MARKT);
          }

          const GEO = {
            context: this.sitemap.GEO,
            pages: [this.sitemap.GEO.Settings.ORT_KATEGORIES, this.sitemap.GEO.Settings.ORTS].filter((page) => page && (admin || !!this.auth.access(page.url.join('/'), routes, true))),
          };
          if (GEO.pages.length > 0) {
            settings.push(GEO);
          }

          const TEXTE = {
            context: this.sitemap.TEXTE,
            pages: [this.sitemap.TEXTE.Settings.VORLAGEN_ARTS].filter((page) => page && (admin || !!this.auth.access(page.url.join('/'), routes, true))),
          };
          if (TEXTE.pages.length > 0) {
            settings.push(TEXTE);
          }

          const ZEITEN = {
            context: this.sitemap.ZEITEN,
            pages: [this.sitemap.ZEITEN.Settings.ZEIT_ARTS, this.sitemap.ZEITEN.Settings.ZEIT_UNTERARTS].filter((page) => admin || !!this.auth.access(page.url.join('/'), routes, true)),
          };
          if (ZEITEN.pages.length > 0) {
            settings.push(ZEITEN);
          }

          const BESICHTIGUNGEN = {
            context: this.sitemap.BESICHTIGUNGEN,
            pages: [this.sitemap.BESICHTIGUNGEN.Settings.FOTO_KATEGORIEN].filter((page) => admin || !!this.auth.access(page.url.join('/'), routes, true)),
          };
          if (BESICHTIGUNGEN.pages.length > 0) {
            settings.push(BESICHTIGUNGEN);
          }

          const EINSTELLUNGEN = {
            context: this.sitemap.EINSTELLUNGEN,
            pages: [this.sitemap.EINSTELLUNGEN.Settings.KONSTANTES].filter((page) => admin || !!this.auth.access(page.url.join('/'), routes, true)),
          };
          if (EINSTELLUNGEN.pages.length > 0) {
            settings.push(EINSTELLUNGEN);
          }

          this.$settings.next(settings);
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
    /* << destroy    */
    this.$settings.getValue().forEach((section) => {
      section.pages.forEach((page) => {
        this.actions.unsubscribe('Zu ' + section.context.title + ' → ' + page.title);
      });
    });
    /*    destroy >> */
  }

  async finishedPostProjekteHochzaehlen(response?: IPostProjekteHochzaehlenResponse) {
    /* << after post-projekte-hochzaehlen    */
    /*    after post-projekte-hochzaehlen >> */
  }

  async finishedPostRechnungenHochzaehlen(response?: IPostRechnungenHochzaehlenResponse) {
    /* << after post-rechnungen-hochzaehlen    */
    /*    after post-rechnungen-hochzaehlen >> */
  }

  back() {
    window.history.back();
  }

  public async submitPostProjekteHochzaehlen(request?: IPostProjekteHochzaehlenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postProjekteHochzaehlen.getValue();
    if (payload) {
      try {
        const response = await this.postProjekteHochzaehlen.request(payload);
        await this.finishedPostProjekteHochzaehlen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostRechnungenHochzaehlen(request?: IPostRechnungenHochzaehlenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postRechnungenHochzaehlen.getValue();
    if (payload) {
      try {
        const response = await this.postRechnungenHochzaehlen.request(payload);
        await this.finishedPostRechnungenHochzaehlen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
