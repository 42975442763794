<!---
  <div formGroupName="mitarbeiter">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">{{ command.Inputs.EMAIL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="EmailErrorTip">
        <ng-template #EmailErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.EMAIL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="email" id="email" [placeholder]="command.Inputs.EMAIL.placeholder" cdkFocusInitial/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="anzeigename">{{ command.Inputs.ANZEIGENAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnzeigenameErrorTip">
        <ng-template #AnzeigenameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANZEIGENAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="anzeigename" id="anzeigename" [placeholder]="command.Inputs.ANZEIGENAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bild">{{ command.Inputs.BILD.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BildErrorTip">
        <ng-template #BildErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BILD.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="bild" id="bild" [placeholder]="command.Inputs.BILD.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <pbc-address-input formControlName="addresse" id="addresse" required="false" ></pbc-address-input>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="vorname">{{ command.Inputs.VORNAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VornameErrorTip">
        <ng-template #VornameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.VORNAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="vorname" id="vorname" [placeholder]="command.Inputs.VORNAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nachname">{{ command.Inputs.NACHNAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NachnameErrorTip">
        <ng-template #NachnameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NACHNAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="nachname" id="nachname" [placeholder]="command.Inputs.NACHNAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="telefon">{{ command.Inputs.TELEFON.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TelefonErrorTip">
        <ng-template #TelefonErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TELEFON.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="telefon" id="telefon" [placeholder]="command.Inputs.TELEFON.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="standort">{{ command.Inputs.STANDORT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StandortErrorTip">
        <ng-template #StandortErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STANDORT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="standort" id="standort" [shapes]="postMitarbeiter.mitarbeiterStandort | async" [placeholder]="command.Inputs.STANDORT.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="rollen">{{ command.Inputs.ROLLEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="RollenErrorTip">
        <ng-template #RollenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ROLLEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="rollen" id="rollen" [shapes]="postMitarbeiter.mitarbeiterRollen | async" [placeholder]="command.Inputs.ROLLEN.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stundenlohn">{{ command.Inputs.STUNDENLOHN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StundenlohnErrorTip">
        <ng-template #StundenlohnErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STUNDENLOHN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="stundenlohn" id="stundenlohn" [nzPlaceHolder]="command.Inputs.STUNDENLOHN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="inaktiv">{{ command.Inputs.INAKTIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="InaktivErrorTip">
        <ng-template #InaktivErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INAKTIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="inaktiv" id="inaktiv"></label>
      </nz-form-control>
    </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="isAdmin">Administrator</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" >
      <label nz-checkbox formControlName="isAdmin" id="isAdmin"></label>
    </nz-form-control>
  </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->
    <div formGroupName="mitarbeiter">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="urlaub" id="urlaub" />
      <input class="cdk-visually-hidden" formControlName="minuten" id="minuten" />
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bild">{{ command.Inputs.BILD.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24">
          <pbc-image-upload formControlName="bild" id="bild"></pbc-image-upload>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">E-Mail-Adresse</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.EMAIL.hint">
          <input nz-input formControlName="email" id="email" placeholder="E-Mail-Adresse" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="anzeigename">{{ command.Inputs.ANZEIGENAME.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.ANZEIGENAME.hint">
          <input nz-input formControlName="anzeigename" id="anzeigename" [placeholder]="command.Inputs.ANZEIGENAME.placeholder" cdkFocusInitial />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="vorname">Namen</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <input nz-input formControlName="vorname" id="vorname" [placeholder]="command.Inputs.VORNAME.placeholder" />
        </nz-form-control>
        <nz-form-control [nzSm]="6" [nzXs]="24">
          <input nz-input formControlName="nachname" id="nachname" [placeholder]="command.Inputs.NACHNAME.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="telefon">{{ command.Inputs.TELEFON.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24">
          <input nz-input formControlName="telefon" id="telefon" [placeholder]="command.Inputs.TELEFON.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <pbc-address-input formControlName="addresse" id="addresse" placeholder="Heimatadresse"></pbc-address-input>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="standort">{{ command.Inputs.STANDORT.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StandortErrorTip">
          <ng-template #StandortErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STANDORT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="standort"
            id="standort"
            [shapes]="postMitarbeiter.mitarbeiterStandort | async"
            [placeholder]="command.Inputs.STANDORT.placeholder"
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="rollen">{{ command.Inputs.ROLLEN.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.ROLLEN.hint">
          <pbc-select-value
            mode="multiple"
            formControlName="rollen"
            id="rollen"
            [shapes]="(postMitarbeiter.shapes$ | async)?.['mitarbeiter.rollen']"
            [placeholder]="command.Inputs.ROLLEN.placeholder"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <ng-container [ngSwitch]="auth.$isAdmin | async">
        <nz-form-item style="width: 100%" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="partner">Zugeordneter Partner</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="PartnerErrorTip">
            <ng-template #PartnerErrorTip let-control>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <pbc-select-value
              formControlName="partner"
              id="partner"
              [shapes]="meta.users$ | async"
              [rollen]="'1e19f5e5-20f4-4ed0-9a72-42701c006f49'"
              placeholder="Zugeordneter Partner"
            ></pbc-select-value>
          </nz-form-control>
        </nz-form-item>
        <input class="cdk-visually-hidden" *ngSwitchCase="false" formControlName="partner" id="partner-hidden" />
      </ng-container>

      <ng-container [ngSwitch]="(auth.$isFinanzen | async) || (auth.$isAdmin | async)">
        <nz-form-item style="width: 100%" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stundenlohn">{{ command.Inputs.STUNDENLOHN.title }}</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StundenlohnErrorTip">
            <ng-template #StundenlohnErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STUNDENLOHN.hint }}</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 150px" formControlName="stundenlohn" id="stundenlohn" [placeholder]="command.Inputs.STUNDENLOHN.placeholder" /> €
          </nz-form-control>
        </nz-form-item>
        <input class="cdk-visually-hidden" *ngSwitchCase="false" formControlName="stundenlohn" id="stundenlohn-hidden" />

        <ng-container *ngSwitchCase="true">
          <br />
          <br />
        </ng-container>

        <nz-form-item style="width: 100%">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tourenplanung">Tourenplanung</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <label nz-checkbox formControlName="tourenplanung" id="tourenplanung"></label>
          </nz-form-control>
        </nz-form-item>

        <ng-container *ngSwitchCase="true">
          <br />
          <br />
        </ng-container>

        <nz-form-item style="width: 100%" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="einstiegsdatum">Einstiegsdatum</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="EinstiegsdatumProTagErrorTip">
            <ng-template #EinstiegsdatumProTagErrorTip let-control>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <nz-date-picker formControlName="einstiegsdatum" id="einstiegsdatum" nzFormat="dd.MM.yyyy" style="width: 150px"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
        <input class="cdk-visually-hidden" *ngSwitchCase="false" formControlName="einstiegsdatum" id="einstiegsdatum-hidden" />

        <nz-form-item style="width: 100%" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stundenProTag">Stunden pro Tag</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StundenProTagErrorTip">
            <ng-template #StundenProTagErrorTip let-control>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 150px" formControlName="stundenProTag" id="stundenProTag" placeholder="Stunden pro Tag" /> Std.
          </nz-form-control>
        </nz-form-item>
        <input class="cdk-visually-hidden" *ngSwitchCase="false" formControlName="stundenProTag" id="stundenProTag-hidden" />

        <nz-form-item style="width: 100%" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stundenübertrag2023">Übertrag Stunden 01. März 2023</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UrlaubsuebertragErrorTip">
            <ng-template #UrlaubsuebertragErrorTip let-control>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 150px" formControlName="stundenübertrag2023" id="stundenübertrag2023" placeholder="Übertrag Stunden 01. März 2023" /> Tage
          </nz-form-control>
        </nz-form-item>
        <input class="cdk-visually-hidden" *ngSwitchCase="false" formControlName="stundenübertrag2023" id="stundenübertrag2023-hidden" />

        <ng-container *ngSwitchCase="true">
          <br />
          <br />
        </ng-container>

        <nz-form-item style="width: 100%" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="urlaubstage">Urlaubstage im Jahr</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UrlaubstageErrorTip">
            <ng-template #UrlaubstageErrorTip let-control>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 150px" formControlName="urlaubstage" id="urlaubstage" placeholder="Urlaubstage im Jahr" /> Tage
          </nz-form-control>
        </nz-form-item>
        <input class="cdk-visually-hidden" *ngSwitchCase="false" formControlName="urlaubstage" id="urlaubstage-hidden" />

        <nz-form-item style="width: 100%" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="urlaubsmonat">Ende der Urlaubsnutzung</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UrlaubsmonatErrorTip">
            <ng-template #UrlaubsmonatErrorTip let-control>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <pbc-select-value formControlName="urlaubsmonat" id="urlaubsmonat" [shapes]="monate" placeholder="Ende der Urlaubsnutzung"></pbc-select-value>
          </nz-form-control>
        </nz-form-item>
        <input class="cdk-visually-hidden" *ngSwitchCase="false" formControlName="urlaubsmonat" id="urlaubsmonat-hidden" />

        <nz-form-item style="width: 100%" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="urlaubsübertrag2023">Übertrag Urlaubstage Jahr 2023</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UrlaubsuebertragErrorTip">
            <ng-template #UrlaubsuebertragErrorTip let-control>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 150px" formControlName="urlaubsübertrag2023" id="urlaubsübertrag2023" placeholder="Übertrag Urlaubstage Jahr 2023" /> Tage
          </nz-form-control>
        </nz-form-item>
        <input class="cdk-visually-hidden" *ngSwitchCase="false" formControlName="urlaubsübertrag2023" id="urlaubsübertrag2023-hidden" />

        <ng-container *ngSwitchCase="true">
          <br />
          <br />
        </ng-container>

        <nz-form-item style="width: 100%">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="no_stundenkontomails">Keine Mails bzgl. Stundenkonto</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <label nz-checkbox formControlName="no_stundenkontomails" id="no_stundenkontomails"></label>
          </nz-form-control>
        </nz-form-item>

        <ng-container *ngSwitchCase="true">
          <br />
          <br />
        </ng-container>

        <nz-form-item style="width: 100%" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="isAdmin">Administrator</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <label nz-checkbox formControlName="isAdmin" id="isAdmin"></label>
          </nz-form-control>
        </nz-form-item>
        <label *ngSwitchCase="false" class="cdk-visually-hidden" nz-checkbox formControlName="isAdmin" id="isAdmin-hidden"></label>

        <nz-form-item style="width: 100%">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="inaktiv">{{ command.Inputs.INAKTIV.title }}</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="InaktivErrorTip">
            <ng-template #InaktivErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INAKTIV.hint }}</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <label nz-checkbox formControlName="inaktiv" id="inaktiv"></label>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </div>
    <!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            Mitarbeiter in der App und dem <i nz-icon nzType="windows" nzTheme="outline"></i> Azure Active Directory speichern
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
