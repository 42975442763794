import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DetailQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IVorlageQueryShapes, IVorlageRequest, IVorlageResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class VorlageService extends DetailQueryService<IVorlageRequest, IVorlageResponse, IVorlageQueryShapes> {
  route = 'texte/vorlage';
  override dependencies = [
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'texte/abschnitt-entfernt',
    'texte/abschnitt-gespeichert',
    'texte/abschnitte-sortiert',
    'texte/vorlage-gespeichert',
    'texte/vorlage-entfernt',
    'felder/feld-gespeichert',
    'felder/feld-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-entfernt',
  ];

  getVorlageFuerBewertungsAnlaesse(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerBewertungsAnlaesse']?.find((v) => v.value === value);
  }

  get vorlageFuerBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerBewertungsAnlaesse'] as ISelection[]) : [])));
  }

  getVorlageFuerKunden(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerKunden']?.find((v) => v.value === value);
  }

  get vorlageFuerKunden(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerKunden'] as ISelection[]) : [])));
  }

  getVorlageFuerKundenArten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerKundenArten']?.find((v) => v.value === value);
  }

  get vorlageFuerKundenArten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerKundenArten'] as ISelection[]) : [])));
  }

  getVorlageFuerKundenGruppen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerKundenGruppen']?.find((v) => v.value === value);
  }

  get vorlageFuerKundenGruppen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerKundenGruppen'] as ISelection[]) : [])));
  }

  getVorlageFuerObjektArten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerObjektArten']?.find((v) => v.value === value);
  }

  get vorlageFuerObjektArten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerObjektArten'] as ISelection[]) : [])));
  }

  getAbschnitteVorlage(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['abschnitte.vorlage']?.find((v) => v.value === value);
  }

  get abschnitteVorlage(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['abschnitte.vorlage'] as ISelection[]) : [])));
  }
}
