import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IPostSyncAzureActiveDirectoryRequest, IPostSyncAzureActiveDirectoryResponse } from 'fa-kt.types';
import { IMitarbeiterPageResolverResult } from '.';
import { MitarbeiterService, PostSyncAzureActiveDirectoryCommandService } from '../..';

@Component({
  selector: 'fa-kt-mitarbeiter-page',
  templateUrl: './mitarbeiter.page.html',
  styleUrls: ['./mitarbeiter.page.css'],
})
export class MitarbeiterPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postSyncAzureActiveDirectory: BehaviorSubject<IPostSyncAzureActiveDirectoryRequest | null> = new BehaviorSubject<IPostSyncAzureActiveDirectoryRequest | null>(null);

  public _profil: ISitemapPage;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public mitarbeiter: MitarbeiterService,
    public postSyncAzureActiveDirectory: PostSyncAzureActiveDirectoryCommandService,
  ) {
    this.page = this.sitemap.PERSONEN.Pages.MITARBEITER;
    this._profil = sitemap.PERSONEN.Pages.PROFIL;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IMitarbeiterPageResolverResult = data.querys;
          /* << resolver    */
          /*    resolver >> */
        }),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
    this.mitarbeiter.sorter$.next({
      'mitarbeiter.inaktiv': {
        ascending: false,
        order: 0,
      },
      'mitarbeiter.isAdmin': {
        ascending: false,
        order: 1,
      },
      'mitarbeiter.email': {
        ascending: true,
        order: 2,
      },
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setMitarbeiter(id: string) {
    /* << set-mitarbeiter    */
    if (this.auth.access('personen/profil') || this.auth.$isAdmin.getValue() || this.auth.$id.getValue() === id) {
      this.navigateToProfil({ id });
    }
    /*    set-mitarbeiter >> */
  }

  async finishedPostSyncAzureActiveDirectory(response?: IPostSyncAzureActiveDirectoryResponse) {
    /* << after post-sync-azure-active-directory    */
    /*    after post-sync-azure-active-directory >> */
  }

  public async navigateToProfil(queryParams: Params = {}) {
    await this.router.navigate(this._profil.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  public async submitPostSyncAzureActiveDirectory(request?: IPostSyncAzureActiveDirectoryRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postSyncAzureActiveDirectory.getValue();
    if (payload) {
      try {
        const response = await this.postSyncAzureActiveDirectory.request(payload);
        await this.finishedPostSyncAzureActiveDirectory(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
