import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { MonitoringService } from '../../../common';
import { AuthService } from '../../services';

export const AuthGuard: CanActivateFn = async (next: ActivatedRouteSnapshot, { url }: RouterStateSnapshot): Promise<boolean> => {
  // const environment = inject(APP_CONFIG) as AppEnvironment;
  const auth = inject(AuthService);
  const router = inject(Router);
  const monitoring = inject(MonitoringService);
  if (!!auth.$id.getValue() && (auth.access(url.toString()) || auth.$isAdmin.getValue())) {
    //  || this.environment.no401
    monitoring.logEvent('authenticatedRoute', { url });
    return true;
  }
  monitoring.logEvent('failedAuthenticatingRoute', {
    url,
    token: auth.$id.getValue(),
    routes: auth.$routes.getValue(),
  });
  auth.$visitedRoute.next(url);
  await router.navigate(['401']);
  return false;
};
